import { decode } from 'jsonwebtoken';
import React, { createContext, useReducer, useState } from 'react';
import { JwtData } from 'typings/types';
import { JWT_TOKEN } from 'shared/constants/app';
import appReducer from '../appReducer/appReducer';
import AppStore from '../appReducer/appReducerModels';
import { AppContextValues, AppProviderProps } from './AppContextModels';

const AppContext = createContext<AppContextValues>({
  state: new AppStore(),
  dispatch: () => ({}),
  onSetJWTData: () => {},
  jwtData: {}
});

const AppContextConsumer = AppContext.Consumer;

const AppContextProvider: React.FC<AppProviderProps> = (
  props: AppProviderProps
) => {
  const [state, dispatch] = useReducer(appReducer, new AppStore());
  // console.log('state:', state);
  const [jwtData, setJwtData] = useState<Partial<JwtData>>(() => {
    const jwt = localStorage.getItem(JWT_TOKEN);
    return jwt ? (decode(jwt) as JwtData) : {};
  });

  const handleSetJWTData = (token: string): void => {
    // console.log(JSON.stringify(decode(token)));
    setJwtData(decode(token) as JwtData);
  };
  const { children } = props;
  return (
    <AppContext.Provider
      value={{ state, dispatch, onSetJWTData: handleSetJWTData, jwtData }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider, AppContextConsumer };
