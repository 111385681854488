/* eslint-disable no-useless-constructor */ // https://github.com/eslint/typescript-eslint-parser/issues/546
export default class OrderPhotosDetails {
  constructor(
    public cart_id: string,
    public Left_Ear_Filename: string,
    public Right_Ear_Filename: string,
    public multipoint_left_ear_file_name: string,
    public multipoint_right_ear_file_name: string
  ) {}
}
