/* eslint-disable no-useless-constructor */
export enum EarType {
  First = 'First',
  Second = 'Second'
}

export enum ImageFormat {
  JPEG = 'jpg'
}
export default class EarImageDetails {
  constructor(
    public userid: string | number,
    public imgData: string,
    public image_format: ImageFormat,
    public ear_type: EarType,
    public cardNumber: string | number,
    public card_number?: string | number,
    public image_data?: string
  ) {}
}
