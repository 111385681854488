import { AxiosResponse } from 'axios';
import api from '../api';
import {
  AccessCodeCartRequest,
  EarImageDetails,
  MatchDetectionEarImageDetails
} from './detectionCommand/detectionCommand';
import MultipointEarImageDetails from './detectionCommand/MultipointEarImageDetails';
import MultipointMatchDetectionEarImageDetails from './detectionCommand/MultipointMatchDetectionEarImageDetails';
import { AccessCodeCartResponse } from './detectionResponse';

const CHECK_GRID_DETECTION = '/check_grid_detection';
const PERFORM_IMAGE_PROCESSING = '/image_processing';
const CHECK_POINT_DETECTION = '/check_point_detection';
const CHECK_MULTI_POINT_DETECTION = '/multipoint_contour_detection';
const MATCHING_ALGORITHM_THREAD = '/matching_algorithm_thread';
const MATCHING_ALGORITHM_PHOENIX = '/multipoint_matching_phoenix';
const MATCHING_ALGORITHM_PHYSICAL = '/multipoint_matching_physical';
const CART_DETAILS_ACCESS_CODE = '/cart_details_access_code';

const validateGridDetection = async (
  imageData: EarImageDetails
): Promise<AxiosResponse> => api.POST(CHECK_GRID_DETECTION, imageData);

const validatePointDetection = async (
  imageData: EarImageDetails
): Promise<AxiosResponse> => api.POST(CHECK_POINT_DETECTION, imageData);

const validateMultiPointDetection = async (
  imageData: MultipointEarImageDetails
): Promise<AxiosResponse> => api.POST(CHECK_MULTI_POINT_DETECTION, imageData);

const performImageProcessing = async (data: any): Promise<AxiosResponse> =>
  api.POST(`${PERFORM_IMAGE_PROCESSING}`, data);

const getMatches = async (
  imageData: MatchDetectionEarImageDetails
): Promise<AxiosResponse> => api.POST(MATCHING_ALGORITHM_THREAD, imageData);

const getPhoenixMatches = async (
  imageData: MultipointMatchDetectionEarImageDetails
): Promise<AxiosResponse> => api.POST(MATCHING_ALGORITHM_PHOENIX, imageData);

const getPhysicalMatches = async (
  imageData: MultipointMatchDetectionEarImageDetails
): Promise<AxiosResponse> => api.POST(MATCHING_ALGORITHM_PHYSICAL, imageData);

const getCardAccessCode = async (
  codeObj: AccessCodeCartRequest
): Promise<AxiosResponse<AccessCodeCartResponse>> =>
  api.POST(CART_DETAILS_ACCESS_CODE, codeObj);

export default {
  validateGridDetection,
  performImageProcessing,
  validatePointDetection,
  validateMultiPointDetection,
  getMatches,
  getPhoenixMatches,
  getPhysicalMatches,
  getCardAccessCode
};
