import {
  CouponDetails,
  PaymentIntentDetails,
  Product,
  ProductDetails,
  SelectedProduct
} from '../storeModels/storeModel';

export default class SalesOrderState {
  products: Array<Product>;

  selected_prod_id: number;

  products_details: Array<ProductDetails>;

  cartItems: Array<SelectedProduct>;

  cartTotal: number;

  placeOrderSuccess: boolean; // true;

  placeOrderFailure: boolean;

  payment_charge: boolean;

  payment_charge_resp: any;

  payment_charge_err: string;

  pay_intent_resp: PaymentIntentDetails;

  pay_intent_error: string;

  pay_intent: boolean;

  sfc_State: boolean;

  couponCode: string;

  couponError: string;

  couponSts: boolean;

  couponObj: CouponDetails;

  prdGalleryData: Array<ProductDetails>;

  isGalleryView: boolean;

  itemAddedFlag: boolean;

  colour: string;

  colourName: string;

  chgQtyPopUp: boolean;

  chgQtyIx: number;

  isOffFlag: boolean;

  constructor() {
    this.products = [];
    this.selected_prod_id = 0;
    this.products_details = [];
    this.cartItems = [];
    this.cartTotal = 0;
    this.placeOrderSuccess = false;
    this.placeOrderFailure = false;
    this.payment_charge = false;
    this.payment_charge_resp = {};
    this.payment_charge_err = '';
    this.pay_intent_resp = new PaymentIntentDetails();
    this.pay_intent_error = '';
    this.pay_intent = false;
    this.sfc_State = false;
    this.couponCode = '';
    this.couponError = '';
    this.couponSts = false;
    this.couponObj = new CouponDetails();
    this.prdGalleryData = [];
    this.isGalleryView = false;
    this.itemAddedFlag = false;
    this.colour = '';
    this.colourName = '';
    this.chgQtyPopUp = false;
    this.chgQtyIx = -1;
    this.isOffFlag = false;
  }
}
