export default class AppStore {
  showLoader: boolean;

  loaderHeader: string;

  isCustom: boolean;

  loaderMessage: string;

  background: string;

  spinnerName: string;

  isError: boolean;

  isSuccess: boolean;

  constructor() {
    this.showLoader = false;
    this.loaderHeader = '';
    this.isCustom = false;
    this.loaderMessage = '';
    this.background = '';
    this.spinnerName = '';
    this.isError = false;
    this.isSuccess = false;
  }
}
