import PaymentMethod from './PaymentMethod';

export default class PaymentIntentDetails {
  id: string;

  object: string;

  amount: number;

  amount_capturable: number;

  amount_received: number;

  application: null;

  application_fee_amount: null;

  canceled_at: null;

  cancellation_reason: null;

  capture_method: string;

  charges: {};

  client_secret: string;

  confirmation_method: string;

  created: number;

  currency: string;

  customer: null;

  description: null;

  invoice: null;

  last_payment_error: null;

  livemode: boolean;

  metadata: {};

  next_action: null;

  on_behalf_of: null;

  payment_method: null;

  payment_method_options: PaymentMethod;

  payment_method_types: Array<string>;

  receipt_email: null;

  review: null;

  setup_future_usage: null;

  shipping: null;

  source: null;

  statement_descriptor: null;

  statement_descriptor_suffix: null;

  status: string;

  transfer_data: null;

  transfer_group: null;

  constructor() {
    this.id = '';

    this.object = '';

    this.amount = 0;

    this.amount_capturable = 0;

    this.amount_received = 0;

    this.application = null;

    this.application_fee_amount = null;

    this.canceled_at = null;

    this.cancellation_reason = null;

    this.capture_method = '';

    this.charges = {};

    this.client_secret = '';

    this.confirmation_method = '';

    this.created = 0;

    this.currency = '';

    this.customer = null;

    this.description = null;

    this.invoice = null;

    this.last_payment_error = null;

    this.livemode = false;

    this.metadata = {};

    this.next_action = null;

    this.on_behalf_of = null;

    this.payment_method = null;

    this.payment_method_options = new PaymentMethod();

    this.payment_method_types = [];

    this.receipt_email = null;

    this.review = null;

    this.setup_future_usage = null;

    this.shipping = null;

    this.source = null;

    this.statement_descriptor = null;

    this.statement_descriptor_suffix = null;

    this.status = '';

    this.transfer_data = null;

    this.transfer_group = null;
  }
}
