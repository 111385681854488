import { UPDATE_LOADER_CONTENT, RESET_LOADER_CONTENT } from '../actionTypes';
import AppStore from './appReducerModels';
import AppCommonAction from '../appActions/appActionsModels';

export default function(
  state: AppStore = new AppStore(),
  action: AppCommonAction
): AppStore {
  // console.log(action);
  switch (action.type) {
    case UPDATE_LOADER_CONTENT: {
      const {
        showLoader,
        loaderHeader,
        isCustom,
        loaderMessage,
        background,
        spinnerName,
        isError,
        isSuccess
      } = action.payload?.loaderContent;

      return {
        ...state,
        loaderHeader,
        isCustom,
        loaderMessage,
        background,
        spinnerName,
        showLoader,
        isError,
        isSuccess
      };
    }

    case RESET_LOADER_CONTENT:
      return new AppStore();
    default:
      return state;
  }
}
