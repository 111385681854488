import leftArrow from 'assets/images/leftArrow.png';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount';
import Footer from '../../../shared/components/Footer';

const PrivacyPolicy: React.FC = () => {
  const history = useHistory();

  const renderContent = (): JSX.Element => (
    <>
      <ScrollToTopOnMount />
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight - 80 }}
      >
        <Col className="faqArticleHead basketTopHead">
          <div
            role="button"
            tabIndex={0}
            onKeyDown={(): void => {
              history.go(-1);
            }}
            className="backArrSpan backArrSpanBasket"
            onClick={(): void => {
              history.go(-1);
            }}
          >
            <img alt="" src={leftArrow} className="backArrImg" />
          </div>
          Privacy Policy
        </Col>
        <Col className="p-lr-25px">
          <Col className="ppHeadDiv">About us</Col>
          <Col className="ppCntDiv">
            <p>
              We&nbsp;are&nbsp;Snugs Earphones Limited (referred to hereafter
              as&nbsp;Snugs),&nbsp;the&nbsp;&lsquo;Controller&rsquo;&nbsp;of the
              personal data you provide to us, as we make decisions on what
              information we hold and what we do with it. We are committed to
              managing and protecting the privacy of your personal information
              in line with&nbsp;all&nbsp;current applicable data privacy laws.
            </p>
            <p>
              This privacy notice outlines what data we collect, why we collect
              it and how we manage it while we are holding it. It also
              highlights your rights with regard to the data.&nbsp;It may change
              from time to time, so please check it at regular intervals.
            </p>
            <p>
              Should you have a concern regarding our management of your
              personal data, or need further information,&nbsp;please do not
              hesitate&nbsp;to&nbsp;contact&nbsp;us:
            </p>
            <ul>
              <li>
                Snugs Earphones Limited,&nbsp;Windwhistle Farm, Treborough,
                Somerset TA23 0QW, UK
              </li>
              <li>
                Email: <a href="mailto:info@snugsfit.com">info@snugsfit.com</a>
              </li>
              <li>
                Telephone: <a href="/#">+44 1984 640582</a>
              </li>
            </ul>
          </Col>
          <Col className="ppHeadDiv">
            What personal information do we collect?
          </Col>
          <Col className="ppCntDiv">
            <p>
              We collect basic personal information including your name, email
              address, phone number, address and ear images in both photographic
              and digital forms to allow us to contact you and provide our
              product or services. We&nbsp;use a&nbsp;third-party&nbsp;payment
              system to collect any payment information.
            </p>
          </Col>
          <Col className="ppHeadDiv">When do we collect it?</Col>
          <Col className="ppCntDiv">
            <p>
              We collect&nbsp;personal&nbsp;information from you when you get in
              touch with us or&nbsp;visit our website,&nbsp;including
              when&nbsp;purchasing or attempting to purchase our products
              services or using our portals.&nbsp;We may
              also&nbsp;acquire&nbsp;your&nbsp;B2B
              contact&nbsp;information&nbsp;from&nbsp;networking or
              similar&nbsp;events.&nbsp;If you would like&nbsp;to know
              more&nbsp;about where we have&nbsp;collected
              your&nbsp;information&nbsp;from, please contact us using
              the&nbsp;details&nbsp;above.
            </p>
          </Col>
          <Col className="ppHeadDiv">Why do we need it?</Col>
          <Col className="ppCntDiv">
            <p>
              We require this basic personal information&nbsp;so that we can:
            </p>
            <ul>
              <li>Enable you to purchase&nbsp;our products or services</li>
              <li>
                Fulfil and manage your product purchase and your enquiries when
                using our product
              </li>
              <li>
                Improve&nbsp;our websites and applications,
                including&nbsp;your&nbsp;user&nbsp;experience
              </li>
              <li>Manage your enquiries</li>
              <li>
                Send you marketing communications, or invite you
                to&nbsp;marketing&nbsp;events, in relation&nbsp;to our products
                and services.&nbsp;You may opt out of marketing at any time
              </li>
            </ul>
            <p>
              We do not collect any personal information from you that we do not
              need to facilitate these tasks.
            </p>
          </Col>
          <Col className="ppHeadDiv">
            What is our lawful basis for using your information?
          </Col>
          <Col className="ppCntDiv">
            <p>
              We consider we have the following lawful bases to process your
              data:
            </p>
            <ul>
              <li>
                We use the lawful basis of Contract for our existing customers
              </li>
              <li>
                Our other business functions, including marketing activities,
                are in&nbsp;Snugs&rsquo; Legitimate Interests&nbsp;to develop
                the business
              </li>
              <li>
                If required to do so,&nbsp;we may need to use your information
                as part of a legal process
              </li>
              <li>For cookies, we use the lawful basis of Consent</li>
            </ul>
          </Col>
          <Col className="ppHeadDiv">How do we protect your information?</Col>
          <Col className="ppCntDiv">
            <p>
              Snugs is committed to protecting your personal data and has
              implemented the appropriate systems and procedures to ensure this.
            </p>
            <p>
              We implement a variety of security measures when a user enters,
              submits, or accesses their information to maintain the safety of
              your personal information. All&nbsp;payment&nbsp;transactions are
              processed through a gateway provider and are not stored or
              processed on our servers.
            </p>
            <p>
              All the personal data we collect is managed by our staff in the
              UK. The information is hosted on servers within the EU, or in the
              USA under the EU-US Privacy Shield agreement. We use some
              processors such as CRMs to manage your data and support queries.
              We may share your data with our business partners when you ask us
              to book your place with partners to take physical impression of a
              customer&rsquo;s ears.&nbsp; We will not share your data with any
              other third parties, unless the law obliges us to do so.
            </p>
          </Col>
          <Col className="ppHeadDiv">Use of Cookies</Col>
          <Col className="ppCntDiv">
            <p>
              Cookies are small files that a site or its service provider
              transfers to your computer&rsquo;s hard drive through your web
              browser (if you allow). It enables the site&rsquo;s systems to
              recognise your browser and capture and remember certain
              information.
            </p>
            <p>Please see our Cookie Policy&nbsp;on our&nbsp;website.</p>
          </Col>
          <Col className="ppHeadDiv">
            How long do we keep your personal information?
          </Col>
          <Col className="ppCntDiv">
            <p>
              If you are, or have been,&nbsp;a customer, we will retain your
              data&nbsp;for&nbsp;up to&nbsp;15 years&nbsp;after the end of your
              contract, or the last point of contact (whichever is
              latest)&nbsp;for&nbsp;marketing as&nbsp;part of our legitimate
              interest&nbsp;and as part of our statutory obligations.
            </p>
            <p>
              If you are not a customer and we send you marketing
              communications, we will keep your information for a maximum
              of&nbsp;3&nbsp;years&nbsp;after the last point of contact.
            </p>
            <p>
              You can opt out of receiving marketing communications at any time
              by contacting us using the details above or unsubscribing on the
              marketing emails we send you.
            </p>
          </Col>
          <Col className="ppHeadDiv">
            What are your rights with regard to your personal data?
          </Col>
          <Col className="ppCntDiv">
            <p>
              You have a number of rights regarding the processing of your
              personal data:
            </p>
            <ul>
              <li>
                You have the right to have your personal data corrected if it is
                not correct or incomplete. Please contact us&nbsp;using the
                above details, or existing customers may update their details in
                their portal
              </li>
              <li>
                You have the right to have your personal data deleted.
                Please&nbsp;contact us using the details provided above. Please
                note that we may not be able to&nbsp;fulfil our contractual
                obligations&nbsp;without your contact details
              </li>
              <li>
                You have the right to have&nbsp;personal&nbsp;data transferred
                to another party. Please ask us if this is what you would like
                to do
              </li>
              <li>
                You have the right of access to your data. Please contact us and
                we will let you know what personal data we hold
              </li>
              <li>
                You can ask us to&nbsp;restrict&nbsp;the use of your
                information, by&nbsp;contacting us on&nbsp;the contact details
                above
              </li>
              <li>
                You have the right to register a complaint regarding the use of
                your personal data to a supervisory authority at any time. The
                Information Commissioner&rsquo;s Office is the UK authority,
                details of which can be found at{' '}
                <a href="http://www.ico.org.uk/">ico.org.uk</a>
              </li>
              <li>
                We do not carry out profiling or automatic decision making
              </li>
            </ul>
          </Col>
          <Col className="ppCntDiv fw-bold">
            Snugs Earphones Privacy Policy
            <br />
            Last Updated:21/09/2019
          </Col>
        </Col>
      </Container>
      <Footer isNotMargin={false} noLinks />
    </>
  );

  return renderContent();
};

export default withRouter(PrivacyPolicy);
