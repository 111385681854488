import React, { useState } from 'react';
import { Spinner } from 'reactstrap';
import styles from './VimeoPlayer.module.scss';

interface VimeoPlayerProps {
  videoSrc: string;
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = (props: VimeoPlayerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { videoSrc } = props;
  return (
    <>
      <div className={styles.wrapper}>
        {isLoading && <Spinner className={styles.spinner} />}
        <iframe
          onLoad={() => setIsLoading(false)}
          title="player"
          src={videoSrc}
          className={styles.iframeStyle}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  );
};

export default VimeoPlayer;
