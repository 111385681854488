export default class CouponDetails {
  id: number;

  usage: number;

  limit_users: number;

  code: string;

  type: string;

  free_shipping: string;

  description: string;

  amount: string;

  redeemed_users: string;

  limit: number;

  expiry_date: string;

  constructor() {
    this.id = 0;
    this.usage = 0;
    this.limit_users = 0;
    this.code = '';
    this.type = '';
    this.free_shipping = '';
    this.description = '';
    this.amount = '';
    this.redeemed_users = '';
    this.limit = 0;
    this.expiry_date = '';
  }
}
