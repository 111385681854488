import 'react-widgets/dist/css/react-widgets.css';
import { JWT_TOKEN } from 'shared/constants/app';

export function ConvertMySqlDateFormat(date: any): string {
  // Convert date format to put in My SQL
  const newDate = new Date(date);
  return `${newDate.getFullYear()}-${newDate.getMonth() +
    1}-${newDate.getDate()}`;
}

export function compareStrings(a: string, b: string): number {
  // Assuming you want case-insensitive comparison
  const string1 = a.toLowerCase();
  const string2 = b.toLowerCase();
  if (string1 < string2) {
    return -1;
  }
  if (string1 > string2) {
    return 1;
  }
  return 0;
}

const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export function FormatDate(field: any, IsTime = false, NoYear = false): string {
  // Formating Date into the format of "15 Aug 2019"
  const fieldDate = new Date(field);
  const Month = fieldDate.getMonth();
  const Day = fieldDate.getDate();
  const Year = fieldDate.getFullYear();
  const Hours = fieldDate.getHours();
  const Mins = fieldDate.getMinutes();
  const hourString = Hours < 10 ? `0${Hours}` : Hours;
  const minsString = Mins < 10 ? `0${Mins}` : Mins;

  // var Tz = fielddate.
  if (IsTime) {
    return `${Months[Month]} ${Day} ${Year}</br>${hourString}:${minsString} EST`;
  }
  if (NoYear) {
    return `${Day} ${Months[Month]}`;
  }
  return `${Day} ${Months[Month]} ${Year}`;
}

export const snugsTheme = {
  title: {
    fontSize: '22px',
    fontColor: '#FFFFFF'
    // backgroundColor: '#363640',
  },
  contextMenu: {
    backgroundColor: '#E91E63',
    fontColor: '#FFFFFF'
  },
  header: {
    fontSize: '12px',
    fontColor: '#FFFFFF'
    // backgroundColor: '#363640',
  },
  rows: {
    fontColor: '#FFFFFF',
    // backgroundColor: '#363640',
    borderColor: 'rgba(255, 255, 255, .12)',
    hoverFontColor: 'black',
    hoverBackgroundColor: 'rgba(0, 0, 0, .24)'
  },
  cells: {
    cellPadding: '48px'
  },
  pagination: {
    fontSize: '13px',
    fontColor: '#FFFFFF',
    // backgroundColor: '#363640',
    buttonFontColor: '#FFFFFF',
    buttonHoverBackground: 'rgba(255, 255, 255, .12)'
  },
  expander: {
    fontColor: '#FFFFFF'
    // backgroundColor: '#363640',
  }
};

export function removeDuplicatesJSON(myArr: any, prop: any): any[] {
  return myArr.filter(
    (obj: any, pos: any, arr: any) =>
      arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos
  );
}

export const ConfiguratorCarouselResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40 // this is optional if you are not using partialVisible props
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
  }
};

export const ProductSelCarouselResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40 // this is optional if you are not using partialVisible props
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
  }
};

export const InstructionsSelCarouselResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 40 // this is optional if you are not using partialVisible props
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30 // this is optional if you are not using partialVisible props
  }
};

export const required = (value: any): string | undefined =>
  value ? undefined : 'Required';

export const maxLength = (max: any): Function => (
  value: any
): string | undefined =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = (min: any): Function => (
  value: any
): string | undefined =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);

export const number = (value: any): string | undefined =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = (min: any): Function => (
  value: any
): string | undefined =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const minValue18 = minValue(18);

export const email = (value: any): string | undefined =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const tooOld = (value: any): string | undefined =>
  value && value > 65 ? 'You might be too old for this' : undefined;

export const alphaNumeric = (value: any): string | undefined =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

export const phoneNumber = (value: any): string | undefined =>
  // value && !/^(0|[1-9][0-9]{9})$/i.test(value)
  //   ? 'Invalid phone number, must be 10 digits'
  //   : undefined

  // value && !/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i.test(value)
  // ? 'Invalid phone number, can be maximum of 11 digits'
  // : undefined

  value && !/^[0-9+.-]{8,15}$/i.test(value)
    ? 'Invalid phone number'
    : undefined;

export const camelCaseToReadableText = (text: string): string =>
  text.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

export const ppFadeOutAnimation = (): void => {
  const element = document.getElementsByClassName(
    'react-confirm-alert-overlay'
  );
  element[0].classList.add('react-confirm-alert-overlay-fadeout');
};

export const unAuthorizedStateHandler = (): void => {
  localStorage.removeItem(JWT_TOKEN);
  window.location.href = '/';
};
