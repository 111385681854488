import { RouteComponentProps } from 'react-router-dom';

const GATrackPageview: React.FC<RouteComponentProps> = ({
  location
}: RouteComponentProps<any>) => {
  if ((window as any).gtag)
    (window as any).gtag('config', 'UA-152434626-1', {
      page_path: location.pathname
    });
  return null;
};

export default GATrackPageview;
