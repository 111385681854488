import AppCommonAction from './appActionsModels';
import { UPDATE_LOADER_CONTENT, RESET_LOADER_CONTENT } from '../actionTypes';

const setLoaderContent = (loaderContent: {
  [key: string]: any;
}): AppCommonAction => ({
  type: UPDATE_LOADER_CONTENT,
  payload: {
    loaderContent
  }
});

const resetLoaderContent = (): AppCommonAction => ({
  type: RESET_LOADER_CONTENT
});

export { setLoaderContent, resetLoaderContent };
