import React from 'react';
import { Col, Container, Modal, ModalBody } from 'reactstrap';
import VimeoPlayer from 'shared/components/VimeoPlayer/VimeoPlayer';
import 'video-react/dist/video-react.css';
import TopBar from '../TopBar';
import styles from './HowItWorksModal.module.scss';

interface HowItWorksModalProps {
  toggle: () => void;
  isOpen: boolean;
}
const HowItWorksModal: React.FC<HowItWorksModalProps> = (
  props: HowItWorksModalProps
) => {
  const { toggle, isOpen } = props;
  return (
    <Modal
      size="xl"
      toggle={toggle}
      className="m-0"
      isOpen={isOpen}
      contentClassName={`${styles.modalContent} min-vw-100`}
    >
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight }}
      >
        <TopBar
          hasBack={false}
          hasLeftButton
          onLeftButtonClick={toggle}
          title="HOW IT WORKS"
        />
        <ModalBody>
          <Col className="pl-0 pr-0 bomperVideoOuter">
            <VimeoPlayer videoSrc="https://player.vimeo.com/video/402537972?color=ffffff&title=0&byline=0&portrait=0&sidedock=0" />
          </Col>
        </ModalBody>
      </Container>
    </Modal>
  );
};

export default HowItWorksModal;
