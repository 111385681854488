import closeIcon from 'assets/images/closeIcon.png';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container } from 'reactstrap';
import 'styles/Instructions.scss';
import styles from './Instructions.module.scss';

const Instructions: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;
  return (
    <>
      <Container
        fluid
        className="pl-0 h-100 pr-0"
        style={{ minHeight: window.innerHeight }}
      >
        <div style={{ minHeight: '83vh' }}>
          <Col className="p-lr-25px text-center color-dflt instHeadDiv position-relative">
            <div className="text-center InstcloseDiv">
              <Link to="/customfit">
                <img
                  alt="chgFitCloseImg"
                  className="cursor-p chgFitCloseImg"
                  src={closeIcon}
                />
              </Link>
            </div>
            PHOTO INSTRUCTIONS
          </Col>

          <Col className={`ml-0 mr-0 InstructionsOuterDiv ${styles.mainBody}`}>
            <Col xs="12" className="InstructionCard">
              <Col className="InstructionCardNumber">one</Col>
              <Col className="InstructionCardHead">Get a friend to help</Col>
              <Col className="InstructionCardContent">
                It’s much easier to get the right photos when someone else is
                taking them for you.
              </Col>
            </Col>

            <Col xs="12" className="InstructionCard">
              <Col className="InstructionCardNumber">two</Col>
              <Col className="InstructionCardHead">Wear the fitting card</Col>
              <Col className="InstructionCardContent">
                You must wear the fitting card over the ear that you’re taking a
                photo of.
              </Col>
            </Col>

            <Col xs="12" className="InstructionCard">
              <Col className="InstructionCardNumber">three</Col>
              <Col className="InstructionCardHead">Keep your ears clear</Col>
              <Col className="InstructionCardContent">
                Remove any jewellery and make sure no hair is covering your ear
                when taking the photo.
              </Col>
            </Col>

            <Col xs="12" className="InstructionCard">
              <Col className="InstructionCardNumber">four</Col>
              <Col className="InstructionCardHead">Find good lighting</Col>
              <Col className="InstructionCardContent">
                Take the photos in clear, bright light. Outside or near a window
                is best.
              </Col>
            </Col>

            <Col xs="12" className="InstructionCard">
              <Col className="InstructionCardNumber">five</Col>
              <Col className="InstructionCardHead">Line up correctly</Col>
              <Col className="InstructionCardContent">
                Make sure your friend gets the whole fitting card inside the
                camera frame.
              </Col>
            </Col>
          </Col>
        </div>
        <div className={`text-center mt-4 pb-4 mb-4 ${styles.btnContainer}`}>
          <Button
            onClick={() => history.push('/customfit')}
            className={styles.letsGoBtn}
          >
            CONTINUE
          </Button>
        </div>
      </Container>
    </>
  );
};

export default Instructions;
