import correctLeftNorm from 'assets/images/correct-left.png';
import correctLeft2x from 'assets/images/correct-left@2x.png';
import correctLeft3x from 'assets/images/correct-left@3x.png';
import correctRightNorm from 'assets/images/correct-right.png';
import correctRight2x from 'assets/images/correct-right@2x.png';
import correctRight3x from 'assets/images/correct-right@3x.png';

import goodLightLeftNorm from 'assets/images/find-good-lighting-left.png';
import goodLightLeft2x from 'assets/images/find-good-lighting-left@2x.png';
import goodLightLeft3x from 'assets/images/find-good-lighting-left@3x.png';
import goodLightRightNorm from 'assets/images/find-good-lighting-right.png';
import goodLightRight2x from 'assets/images/find-good-lighting-right@2x.png';
import goodLightRight3x from 'assets/images/find-good-lighting-right@3x.png';

import earsClearLeftNorm from 'assets/images/keep-ears-clear-left.png';
import earsClearLeft2x from 'assets/images/keep-ears-clear-left@2x.png';
import earsClearLeft3x from 'assets/images/keep-ears-clear-left@3x.png';
import earsClearRightNorm from 'assets/images/keep-ears-clear-right.png';
import earsClearRight2x from 'assets/images/keep-ears-clear-right@2x.png';
import earsClearRight3x from 'assets/images/keep-ears-clear-right@3x.png';

import lineUpLeftNorm from 'assets/images/line-up-correct-left.png';
import lineUpLeft2x from 'assets/images/line-up-correct-left@2x.png';
import lineUpLeft3x from 'assets/images/line-up-correct-left@3x.png';
import lineUpRightNorm from 'assets/images/line-up-correct-right.png';
import lineUpRight2x from 'assets/images/line-up-correct-right@2x.png';
import lineUpRight3x from 'assets/images/line-up-correct-right@3x.png';

export default {
  correctLeftNorm,
  correctLeft2x,
  correctLeft3x,
  correctRightNorm,
  correctRight2x,
  correctRight3x,
  goodLightLeftNorm,
  goodLightLeft2x,
  goodLightLeft3x,
  goodLightRightNorm,
  goodLightRight2x,
  goodLightRight3x,
  earsClearLeftNorm,
  earsClearLeft2x,
  earsClearLeft3x,
  earsClearRightNorm,
  earsClearRight2x,
  earsClearRight3x,
  lineUpLeftNorm,
  lineUpLeft2x,
  lineUpLeft3x,
  lineUpRightNorm,
  lineUpRight2x,
  lineUpRight3x
};
