import React from 'react';
import { Col } from 'reactstrap';

type CustomFitHeadingProps = {
  title: string;
};
const CustomFitHeading: React.FC<CustomFitHeadingProps> = (
  props: CustomFitHeadingProps
) => {
  const { title } = props;
  return <Col className="pl-0 pr-0 sixDigitHeadDiv">{title || ''}</Col>;
};

export default CustomFitHeading;
