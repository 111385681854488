import SalesOrderState from './salesOrderReducerModel';
import SalesOrderCommonAction from '../salesOrderActions/salesOrderActionModels';
import {
  PRODUCTS_SET,
  PRODUCTS_SUB_SET,
  SET_SELECTED_PRODUCT,
  ADD_CART,
  REMOVE_CART_ITEM,
  CHANGE_QTY_PP_TOGGLE,
  CHANGE_ITEM_QUANTITY,
  PAYMENT_CHARGE,
  PAYMENT_CHARGE_ERROR,
  PAYMENT_INTENT,
  SET_COUPON_CODE,
  COUPON_ERR,
  COUPON_SUCCESS,
  SET_PRD_GALLERY,
  ITEM_ADDED_FLAG,
  SET_COLOR,
  RESET_COLOR,
  RESET_PRODUCT_DETAILS,
  REMOVE_COUPON,
  RESET_PAYMENT_STATE,
  SET_ORDER_STATUS,
  PAYMENT_INTENT_ERROR
} from '../actionTypes';
import { PaymentIntentDetails, CouponDetails } from '../storeModels/storeModel';

const reducer = (
  state = new SalesOrderState(),
  action: SalesOrderCommonAction
): SalesOrderState => {
  switch (action.type) {
    case PRODUCTS_SET:
      return {
        ...state,
        products: action.payload.products,
        selected_prod_id:
          action.payload.products?.length > 0
            ? action.payload.products[0].Product_id
            : -1
      };

    case PRODUCTS_SUB_SET:
      return {
        ...state,
        products_details: action.payload.results
      };

    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selected_prod_id: action.payload.id
      };

    case ADD_CART: {
      const newItems = [...action.payload.cart];
      let newCartTotal = newItems.reduce(
        (prev, cur) =>
          prev + parseFloat(cur.Product_Price) * parseFloat(cur.quantity),
        0
      );
      newCartTotal = Math.round(newCartTotal * 100) / 100;

      return {
        ...state,
        cartItems: newItems,
        itemAddedFlag: true,
        cartTotal: newCartTotal
      };
    }
    case SET_PRD_GALLERY:
      return {
        ...state,
        prdGalleryData: action.payload.data,
        isGalleryView: action.payload.data ? !state.isGalleryView : false
      };

    case ITEM_ADDED_FLAG:
      return {
        ...state,
        itemAddedFlag: !state.itemAddedFlag
      };
    case SET_COLOR:
      return {
        ...state,
        colour: action.payload.colour,
        colourName: action.payload.colourName
      };
    case RESET_COLOR:
      return {
        ...state,
        colour: '',
        colourName: ''
      };
    case RESET_PRODUCT_DETAILS:
      return {
        ...state,
        colour: '',
        colourName: '',
        products_details: []
      };

    case CHANGE_QTY_PP_TOGGLE:
      return {
        ...state,
        chgQtyPopUp: action.payload.index > -1,
        chgQtyIx: action.payload.index > -1 ? action.payload.index : -1
      };

    case CHANGE_ITEM_QUANTITY: {
      const chgQtyItems = [...state.cartItems];

      if (action.payload.isIncrease) {
        chgQtyItems[action.payload.index].quantity = chgQtyItems[
          action.payload.index
        ]?.quantity
          ? chgQtyItems[action.payload.index]?.quantity + 1
          : 0;
      } else {
        chgQtyItems[action.payload.index].quantity = chgQtyItems[
          action.payload.index
        ]?.quantity
          ? chgQtyItems[action.payload.index]?.quantity - 1
          : 0;
      }

      let chgQtyTotal = chgQtyItems.reduce(
        (prev, cur) =>
          prev +
          parseFloat(cur.Product_Price) *
            parseFloat(cur.quantity ? cur.quantity.toString() : '0'),
        0
      );
      chgQtyTotal = Math.round(chgQtyTotal * 100) / 100;

      return {
        ...state,
        cartItems: chgQtyItems,
        cartTotal: chgQtyTotal
      };
    }

    case REMOVE_CART_ITEM: {
      const cartItems = [...state.cartItems];
      cartItems.splice(action.payload.index, 1);
      let updatedCartTotal = cartItems.reduce(
        (prev, cur) =>
          prev +
          parseFloat(cur.Product_Price) *
            parseFloat(cur.quantity ? cur.quantity.toString() : '0'),
        0
      );
      updatedCartTotal = Math.round(updatedCartTotal * 100) / 100;

      return {
        ...state,
        cartItems,
        cartTotal: updatedCartTotal,
        chgQtyPopUp: false,
        chgQtyIx: -1
      };
    }

    case RESET_PAYMENT_STATE:
      return {
        ...state,
        couponCode: '',
        couponError: '',
        couponSts: false,
        couponObj: new CouponDetails(),
        isOffFlag: false,
        payment_charge: false,
        payment_charge_resp: {},
        payment_charge_err: ''
      };

    case PAYMENT_CHARGE:
      return {
        ...state,
        payment_charge: true,
        payment_charge_resp: action.payload.payResp,
        payment_charge_err: ''
      };

    case PAYMENT_CHARGE_ERROR:
      return {
        ...state,
        payment_charge: false,
        payment_charge_resp: {},
        payment_charge_err: action.payload.error
      };

    case SET_ORDER_STATUS:
      return {
        ...state,
        placeOrderSuccess: action.payload.status === true,
        placeOrderFailure: action.payload.status === false,
        cartItems: action.payload.status === true ? [] : state.cartItems
      };

    case COUPON_SUCCESS:
      return {
        ...state,
        couponError: '',
        couponSts: true,
        couponObj: action.payload.couponObjState.couponObj,
        isOffFlag: action.payload.couponObjState.isOffFlag
      };

    case COUPON_ERR:
      return {
        ...state,
        couponCode: '',
        couponError: action.payload.error,
        couponSts: false,
        couponObj: new CouponDetails(),
        isOffFlag: false
      };

    case REMOVE_COUPON:
      return {
        ...state,
        couponCode: '',
        couponError: '',
        couponSts: false,
        couponObj: new CouponDetails(),
        isOffFlag: false
      };

    case SET_COUPON_CODE:
      return {
        ...state,
        couponCode: action.payload.coupon
      };

    case PAYMENT_INTENT:
      return {
        ...state,
        pay_intent_resp: action.payload.paymentDetails,
        pay_intent_error: '',
        pay_intent: true
      };

    case PAYMENT_INTENT_ERROR:
      return {
        ...state,
        pay_intent_resp: new PaymentIntentDetails(),
        pay_intent_error: action.payload.error,
        pay_intent: false
      };

    default:
      return state;
  }
};

export default reducer;
