import SnugsGoLogo from 'assets/images/Icons/SnugsGo Logo.svg';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container } from 'reactstrap';
import Header from 'shared/components/Header';
import 'styles/Home.scss';
import styles from './LandingPage.module.scss';

const customFitSteps = [
  {
    step: 1,
    title: '6-digit code',
    description: 'It’s in the custom fit email we sent'
  },
  {
    step: 2,
    title: 'Fitting card',
    description: 'It’s in your custom fit pack'
  },
  {
    step: 3,
    title: 'A friend',
    description: 'To help take the photos'
  }
];

const LandingPage: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;
  return (
    <Container
      fluid
      className="pl-0 pr-0"
      style={{ minHeight: window.innerHeight }}
    >
      <div style={{ minHeight: window.innerHeight - 80 }}>
        <Col className="homgTopBg pl-0 pr-0">
          <Col className="pl-0 pr-0 headerHomeOuter">
            <Header isHome />
          </Col>
        </Col>
        <Col className={`${styles.mainBody} align-middle d-flex`}>
          <div className="m-auto">
            <Col className="pl-0 pr-0 snugsGoDiv text-center">
              <img className="snugsGoLogoImg" src={SnugsGoLogo} alt="" />
            </Col>
            <Col className="pl-0 pr-0">
              <Col className="homeTopSec2Cnt text-center">Custom fitting</Col>
            </Col>
            <Col className={`whatAreSnugs ${styles.helloInfoContainer}`}>
              <Col className={`pl-0 pr-0 ${styles.helloInfo}`}>
                Hello. Before we start your online custom fitting, make sure you
                have the following:
              </Col>
            </Col>
            {customFitSteps.map(item => (
              <Col
                key={item.step}
                className={`pl-0 pr-0 text-center ${styles.stepContainer}`}
              >
                <Col className={`pl-0 pr-0 ${styles.stepTitle}`}>
                  {item.title}
                </Col>
                <Col className={`pl-0 pr-0 ${styles.stepDescription}`}>
                  {item.description}
                </Col>
              </Col>
            ))}
          </div>
        </Col>
      </div>
      <div className={`text-center ${styles.btnContainer}`}>
        <Button
          className={styles.letsGoBtn}
          id="orderfc"
          onClick={() => history.push('/customfit')}
        >
          LET'S GO
        </Button>
      </div>
    </Container>
  );
};

export default LandingPage;
