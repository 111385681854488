import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faCamera,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faCircle,
  faEllipsisV,
  faExclamationTriangle,
  faInfoCircle,
  faMinus,
  faPenSquare,
  faPlus,
  faPlusSquare,
  faShoppingBag,
  faShoppingCart,
  faStroopwafel,
  faTimes,
  faUser,
  faUserCircle
} from '@fortawesome/free-solid-svg-icons';
import { FittingProvider } from 'modules/fitting/store/FittingContext/FittingContext';
import RootNavigator from 'navigation/navigators/RootNavigator';
import 'prevent-pull-refresh';
import React from 'react';
import { AppContextProvider } from 'shared/store/appStore/AppContext/AppContext';
import { SalesOrderContextProvider } from 'shared/store/salesOrder/salesOrderContext/SalesOrderContext';
import './App.scss';

library.add(
  faStroopwafel,
  faChevronLeft,
  faUser,
  faUserCircle,
  faPenSquare,
  faPlusSquare,
  faCircle,
  faCheck,
  faTimes,
  faShoppingCart,
  faInfoCircle,
  faFacebook,
  faGoogle,
  faCamera,
  faChevronDown,
  faShoppingBag,
  faBars,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faPlus,
  faEllipsisV,
  faMinus,
  faExclamationTriangle
);

const App: React.FC = () => (
  <AppContextProvider>
    <SalesOrderContextProvider>
      <FittingProvider>
        <RootNavigator />
      </FittingProvider>
    </SalesOrderContextProvider>
  </AppContextProvider>
);

export default App;
