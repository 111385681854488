import { FittingConsumer } from 'modules/fitting/store/FittingContext/FittingContext';
import React from 'react';
import { Container } from 'reactstrap';
import { AppContextConsumer } from 'shared/store/appStore/AppContext/AppContext';
import 'styles/CustomFit.scss';
import { RouteComponentProps } from 'react-router-dom';
import CustomFitComplete from './components/CustomFitComplete';
import CustomFitEarPhotos from './components/CustomFitEarPhotos/CustomFitEarPhotos';
import CustomFitSixDigit from './components/CustomFitSixDigit/CustomFitSixDigit';
import CustomFitWelcome from './components/CustomFitWelcome/CustomFitWelcome';

const CustomFit: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => (
  <FittingConsumer>
    {({ store }) => (
      <AppContextConsumer>
        {({ jwtData }) => (
          <Container fluid className="pl-0 pr-0">
            {store.customFitStep === 1 ? (
              <>
                <CustomFitSixDigit {...props} />
              </>
            ) : (
              <>
                {store.customFitStep === 2 ? (
                  <>
                    <CustomFitWelcome />
                  </>
                ) : (
                  <>
                    {store.customFitStep === 3 ? (
                      <>
                        <CustomFitEarPhotos
                          {...props}
                          userDetails={jwtData.identity}
                          match={props.match}
                        />
                      </>
                    ) : (
                      <>
                        <CustomFitComplete />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Container>
        )}
      </AppContextConsumer>
    )}
  </FittingConsumer>
);

export default CustomFit;
