import {
  CHECK_FIRST,
  CHECK_SECOND,
  GRID_POINT_DETECT,
  HIDE_LOADER,
  RESET_STATE,
  RESET_STATE_FIRST,
  RESET_STATE_FIRST_TRY,
  RESET_STATE_SECOND,
  RESET_STATE_SECOND_TRY,
  SET_ACCESS_CODE_CART,
  SET_CARD_NO,
  SET_CUSTOM_FIT_STEP,
  SHOW_LOADER
} from '../actionTypes';
import FittingCommonAction from '../fittingActions/fittingActionsModels';
import { EarType } from '../storeModels';
import FittingStore from './fittingReducerModels';

export default function fittingReducer(
  state: FittingStore,
  action: FittingCommonAction
): FittingStore {
  // console.log('action', action);
  switch (action.type) {
    case SET_CUSTOM_FIT_STEP:
      return {
        ...state,
        customFitStep: action.payload.step
      };

    case SET_ACCESS_CODE_CART:
      return {
        ...state,
        accessCodeCart: action.payload.cart
      };

    case SET_CARD_NO:
      return {
        ...state,
        cardNumber: action.payload.cardNumber
      };

    case GRID_POINT_DETECT:
      if (action.payload.input.ear_type === EarType.First) {
        return {
          ...state,
          firstImgData: action.payload.input.imgData,
          currentOPEar: EarType.First, // 'Left',
          firstGridDetectResponse: action.payload.gridResponse,
          firstGridStatus: !!action.payload.gridResponse?.detect_status,
          firstPointDetectResponse:
            action.payload.pointResponse === null
              ? {}
              : action.payload.pointResponse,
          firstMultiPointDetectResponse:
            action.payload.multiPointResponse === null
              ? {}
              : action.payload.multiPointResponse,
          firstPointStatus:
            action.payload.pointResponse?.detect_status === 'success',
          firstMultiPointStatus:
            action.payload.multiPointResponse?.detect_status === 'success',
          // firstChecked: action.payload.gridResponse.detect_status && action.payload.pointResponse.detect_status === 'success' ? false : true,
          firstEarType:
            action.payload.gridResponse?.detect_status &&
            action.payload.pointResponse?.detect_status === 'success'
              ? action.payload.pointResponse?.detect_ear_type
              : '',
          // secondChecked: action.payload.gridResponse.detect_status && action.payload.pointResponse.detect_status === 'success' ? true : false,
          isLoader: false,
          loadMessage: '',
          firstImgProcessingStatus:
            action.payload.imgProcessingResponse?.detect_status === 'success',
          firstImgProcessingResponse: action.payload.imgProcessingResponse
        };
      }
      return {
        ...state,
        secondImgData: action.payload.input.imgData,
        currentOPEar: EarType.Second,
        secondGridDetectResponse: action.payload.gridResponse,
        secondGridStatus: !!action.payload.gridResponse?.detect_status,
        secondPointDetectResponse:
          action.payload.pointResponse === null
            ? {}
            : action.payload.pointResponse,
        secondMultiPointDetectResponse:
          action.payload.multiPointResponse === null
            ? {}
            : action.payload.multiPointResponse,
        secondPointStatus: !!(
          action.payload.gridResponse?.detect_status &&
          action.payload.pointResponse?.detect_status === 'success'
        ),
        secondMultiPointStatus: !!(
          action.payload.gridResponse?.detect_status &&
          action.payload.multiPointResponse?.detect_status === 'success'
        ),
        secondEarType:
          action.payload.gridResponse?.detect_status &&
          action.payload.pointResponse?.detect_status === 'success'
            ? action.payload.pointResponse?.detect_ear_type
            : '',
        isLoader: false,
        loadMessage: '',
        secondImgProcessingStatus:
          action.payload.imgProcessingResponse?.detect_status === 'success',
        secondImgProcessingResponse: action.payload.imgProcessingResponse
      };

    case CHECK_FIRST:
      return {
        ...state,
        firstChecked: true,
        secondChecked: false,
        currentOPEar: ''
      };

    case CHECK_SECOND:
      return {
        ...state,
        firstChecked: false,
        secondChecked: true,
        currentOPEar: ''
      };
    case SHOW_LOADER:
      return {
        ...state,
        isLoader: true,
        loadMessage: action.payload.msg
      };

    case HIDE_LOADER:
      return {
        ...state,
        isLoader: false,
        loadMessage: ''
      };
    case RESET_STATE_FIRST:
      return {
        ...state,
        isLoader: false,
        loadMessage: '',
        apiErrorMessage: '',
        currentOPEar: '',
        firstChecked: true,
        secondChecked: false,
        firstImgData: '',
        firstGridStatus: false,
        firstGridDetectResponse: {},
        firstPointStatus: false,
        firstPointDetectResponse: {},
        firstMultiPointDetectResponse: {},
        firstMultiPointStatus: false,
        firstEarType: '',
        firstImgProcessingStatus: false,
        firstImgProcessingResponse: {}
      };

    case RESET_STATE_SECOND:
      return {
        ...state,
        isLoader: false,
        loadMessage: '',
        apiErrorMessage: '',
        currentOPEar: EarType.First,
        firstChecked: false,
        secondChecked: true,
        secondImgData: '',
        secondGridStatus: false,
        secondGridDetectResponse: {},
        secondPointStatus: false,
        secondPointDetectResponse: {},
        secondMultiPointDetectResponse: {},
        secondMultiPointStatus: false,
        secondEarType: '',
        secondImgProcessingStatus: false,
        secondImgProcessingResponse: {}
      };

    case RESET_STATE_FIRST_TRY:
      return {
        ...state,
        isLoader: false,
        loadMessage: '',
        apiErrorMessage: '',
        currentOPEar: 'FIRSTTRY',
        firstChecked: true,
        secondChecked: false,
        firstImgData: '',
        firstGridStatus: false,
        firstGridDetectResponse: {},
        firstPointStatus: false,
        firstPointDetectResponse: {},
        firstMultiPointDetectResponse: {},
        firstMultiPointStatus: false,
        firstEarType: '',
        firstImgProcessingStatus: false,
        firstImgProcessingResponse: {}
      };

    case RESET_STATE_SECOND_TRY:
      return {
        ...state,
        isLoader: false,
        loadMessage: '',
        apiErrorMessage: '',
        currentOPEar: 'SECONDTRY',
        firstChecked: false,
        secondChecked: true,
        secondImgData: '',
        secondGridStatus: false,
        secondGridDetectResponse: {},
        secondPointStatus: false,
        secondPointDetectResponse: {},
        secondMultiPointDetectResponse: {},
        secondMultiPointStatus: false,
        secondEarType: '',
        secondImgProcessingStatus: false,
        secondImgProcessingResponse: {}
      };

    case RESET_STATE:
      return new FittingStore();

    default:
      return state;
  }
}
