import { AxiosResponse } from 'axios';
import api from '../api';
import {
  OrderDetails,
  OrderPaymentIntent,
  CouponDetails,
  LogData,
  OrderPhotosDetails
} from './orderServiceCommand/orderServiceCommand';
import {
  PaymentIntentDetailsResponse,
  CouponCheckResponse
} from './orderServiceResponse';

const ORDER_UPDATE = '/order_update';
const ADD_ORDER = '/app_order';
const CREATE_CUSTOMER_PAYMENT_INTENT = '/customer_payment_intent_create';
const SEND_FITTING_CARD = '/send_fitting_card';
const CUSTOMER_COUPON_CHECK_CUSTOM = '/customer_coupon_check_custom';
const ORDER_INFO_LOG = '/order_info_log';

const updateOrder = async (
  orderData: OrderPhotosDetails
): Promise<AxiosResponse> => api.POST(ORDER_UPDATE, orderData);

const sendFittingCard = async (formValues: {
  [key: string]: any;
}): Promise<AxiosResponse> => api.POST(SEND_FITTING_CARD, formValues);

// #region checkout requests
const addOrder = async (orderData: OrderDetails): Promise<AxiosResponse> =>
  api.POST(ADD_ORDER, orderData);

const createCustomerPaymentIntent = async (
  orderData: OrderPaymentIntent
): Promise<AxiosResponse<PaymentIntentDetailsResponse>> =>
  api.POST(CREATE_CUSTOMER_PAYMENT_INTENT, orderData);

const checkCustomerCoupon = async (
  couponData: CouponDetails
): Promise<AxiosResponse<CouponCheckResponse>> =>
  api.POST(CUSTOMER_COUPON_CHECK_CUSTOM, couponData);

const logOrderInfo = async (data: LogData): Promise<AxiosResponse> =>
  api.POST(ORDER_INFO_LOG, data);

// #endregion

export default {
  updateOrder,
  sendFittingCard,
  addOrder,
  createCustomerPaymentIntent,
  checkCustomerCoupon,
  logOrderInfo
};
