import arrPurpleDown from 'assets/images/Icons/Arrow-Down-Purple.svg';
import mobileIcon from 'assets/images/Icons/mobile-message-icon.svg';
import snugslogoPurple from 'assets/images/Icons/Snugs-Logo-Purple.svg';
import SnugsGoLogo from 'assets/images/Icons/SnugsGo Logo.svg';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import 'styles/DesktopErrScreen.scss';

const DesktopErrScreen: React.FC = () => (
  <>
    <Container
      fluid
      className="desktopErrContainer"
      style={{ minHeight: window.innerHeight - 60 }}
    >
      <Col className="desktopErrLogoDiv text-center">
        <img alt="" src={snugslogoPurple} />
      </Col>
      <Col className="desktopErrGoLogoDiv text-center">
        <img alt="" src={SnugsGoLogo} />
      </Col>
      <Col className="desktopErrSec3 text-center">
        Custom-fit <br />
        earphone tips
      </Col>
      <Col className="desktopErrSec4 text-center">
        <img alt="" src={arrPurpleDown} />
      </Col>
      <Row className="ml-0 mr-0">
        <Col className="" xs="4" />
        <Col className="desktopErrSec5" xs="4">
          <Col className="desktopErrSec51 text-center">
            <img alt="" src={mobileIcon} />
          </Col>
          <Col className="desktopErrSec52 text-center">
            Please visit us on your mobile
          </Col>
          <Col className="desktopErrSec53 text-center">
            We’ve designed the site to work like an app, so it’s best viewed on
            your mobile device.
          </Col>
        </Col>
        <Col className="" xs="4" />
      </Row>
    </Container>
    <Col className="pl-0 pr-0 text-center desktopErrFooter">© Snugs Ltd</Col>
  </>
);

export default DesktopErrScreen;
