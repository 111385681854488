import {
  CHECK_FIRST,
  CHECK_SECOND,
  GRID_POINT_DETECT,
  HIDE_LOADER,
  RESET_STATE,
  RESET_STATE_FIRST,
  RESET_STATE_FIRST_TRY,
  RESET_STATE_SECOND,
  RESET_STATE_SECOND_TRY,
  SET_ACCESS_CODE_CART,
  SET_CARD_NO,
  SET_CUSTOM_FIT_STEP,
  SHOW_LOADER
} from '../actionTypes';
import { EarImageData } from '../storeModels';
import FittingCommonAction, {
  GridPointDetectPayload
} from './fittingActionsModels';

function setCustomFitStep(step: number): FittingCommonAction {
  return {
    type: SET_CUSTOM_FIT_STEP,
    payload: { step }
  };
}

function setAccessCart(cart: any[] = []): FittingCommonAction {
  return {
    type: SET_ACCESS_CODE_CART,
    payload: { cart }
  };
}

function resetState(): FittingCommonAction {
  return {
    type: RESET_STATE,
    payload: {}
  };
}

function setCardNumber(cardNumber: string): FittingCommonAction {
  return {
    type: SET_CARD_NO,
    payload: { cardNumber }
  };
}

function gridPointDetect(
  gridResponse: any,
  imgProcessingResponse: any,
  pointResponse: any,
  multiPointResponse: any,
  input: EarImageData
): FittingCommonAction<GridPointDetectPayload> {
  return {
    type: GRID_POINT_DETECT,
    payload: {
      gridResponse,
      input,
      pointResponse,
      multiPointResponse,
      imgProcessingResponse
    }
  };
}
function checkFirstLR(): FittingCommonAction {
  return {
    type: CHECK_FIRST,
    payload: {}
  };
}

function checkSecondLR(): FittingCommonAction {
  return {
    type: CHECK_SECOND,
    payload: {}
  };
}

function showLoader(message: string): FittingCommonAction {
  return {
    type: SHOW_LOADER,
    payload: { msg: message }
  };
}

function hideLoader(): FittingCommonAction {
  return {
    type: HIDE_LOADER,
    payload: {}
  };
}

function resetStateFirst(): FittingCommonAction {
  return {
    type: RESET_STATE_FIRST,
    payload: {}
  };
}

function resetStateSecond(): FittingCommonAction {
  return {
    type: RESET_STATE_SECOND,
    payload: {}
  };
}

function resetStateFirstTry(): FittingCommonAction {
  return {
    type: RESET_STATE_FIRST_TRY,
    payload: {}
  };
}

function resetStateSecondTry(): FittingCommonAction {
  return {
    type: RESET_STATE_SECOND_TRY,
    payload: {}
  };
}

export {
  setCustomFitStep,
  gridPointDetect,
  setAccessCart,
  resetState,
  setCardNumber,
  checkFirstLR,
  checkSecondLR,
  showLoader,
  hideLoader,
  resetStateFirst,
  resetStateSecond,
  resetStateFirstTry,
  resetStateSecondTry
};
