export const PRODUCTS_SET = 'PRODUCTS_SET';
export const ADD_CART = 'ADD_CART';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const PAYMENT_CHARGE = 'PAYMENT_CHARGE';
export const PAYMENT_CHARGE_ERROR = 'PAYMENT_CHARGE_ERROR';
export const PAYMENT_INTENT = 'PAYMENT_INTENT';
export const PAYMENT_INTENT_ERROR = 'PAYMENT_INTENT_ERROR';
export const PRODUCTS_SUB_SET = 'PRODUCTS_SUB_SET';
export const RESET_PRODUCT_DETAILS = 'RESET_PRODUCT_DETAILS';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';
export const COUPON_ERR = 'COUPON_ERR';
export const COUPON_SUCCESS = 'COUPON_SUCCESS';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE';
export const SET_PRD_GALLERY = 'SET_PRD_GALLERY';
export const ITEM_ADDED_FLAG = 'ITEM_ADDED_FLAG';
export const SET_COLOR = 'SET_COLOR';
export const RESET_COLOR = 'RESET_COLOR';
export const CHANGE_QTY_PP_TOGGLE = 'CHANGE_QTY_PP_TOGGLE';
export const CHANGE_ITEM_QUANTITY = 'CHANGE_ITEM_QUANTITY';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
