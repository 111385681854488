import React, { createContext, useReducer } from 'react';
import reducer from '../salesOrderReducer/salesOrderReducer';
import SalesOrderState from '../salesOrderReducer/salesOrderReducerModel';
import {
  SalesOrderContextValues,
  SalesOrderProviderProps
} from './salesOrderContextModels';

const SalesOrderContext = createContext<SalesOrderContextValues>({
  state: new SalesOrderState(),
  dispatch: () => {}
});

const SalesOrderContextProvider: React.FC<SalesOrderProviderProps> = (
  props: SalesOrderProviderProps
) => {
  const [state, dispatch] = useReducer(reducer, new SalesOrderState());
  const { children } = props;
  return (
    <SalesOrderContext.Provider value={{ state, dispatch }}>
      {children}
    </SalesOrderContext.Provider>
  );
};

export { SalesOrderContext, SalesOrderContextProvider };
