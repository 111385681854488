import React from 'react';
import { CustomUiOptions } from 'react-confirm-alert';
import { Button } from 'reactstrap';

type OpenInSafariWarningProps = CustomUiOptions;
const OpenInSafariWarning: React.FC<OpenInSafariWarningProps> = (
  props: OpenInSafariWarningProps
) => {
  const { onClose } = props;
  return (
    <div className="custom-ui takePhotoErrPP">
      <div className="react-confirm-alert-body">
        <h1>Please open in Safari</h1>
        <div className="wrngEarDivCam">
          Due to settings of Apple devices camera will not be accessible by this
          browser. Please open the application in Safari.
        </div>
      </div>
      <div className="text-center">
        <Button
          className="btn snugsTopBtn"
          onClick={() => {
            onClose();
            window.location.href = '/';
          }}
        >
          CLOSE
        </Button>
      </div>
    </div>
  );
};

export default OpenInSafariWarning;
