import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { JWT_TOKEN } from 'shared/constants/app';
import { unAuthorizedStateHandler } from '../shared/utils/utils';

const BASE_URL = `${process.env.REACT_APP_API_URL}`;

const defaultRequestHeaders = (): object => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`
});

const GET = async (
  endpoint: string,
  params?: { [key: string]: any } | null,
  headers?: { [key: string]: any } | null
): Promise<any> => {
  const url = `${BASE_URL}${endpoint}`;
  const options: AxiosRequestConfig = {
    headers: {
      ...defaultRequestHeaders(),
      ...headers
    },
    params
  };
  return axios.get(url, options);
};

const GETBLOB = async (
  endpoint: string,
  params: { [key: string]: any } | null,
  headers: { [key: string]: any } | null
): Promise<any> => {
  const url = `${BASE_URL}${endpoint}`;
  const options: { [key: string]: any } = {
    headers: {
      ...defaultRequestHeaders(),
      ...headers
    },
    responseType: 'blob',
    params
  };
  return axios.get(url, options);
};

const POST = async (
  endpoint: string,
  body: { [key: string]: any } | null,
  params?: { [key: string]: any } | null,
  headers?: { [key: string]: any } | null
): Promise<any> => {
  const url = `${BASE_URL}${endpoint}`;
  const options: { [key: string]: any } = {
    headers: {
      ...defaultRequestHeaders(),
      ...headers
    },
    params
  };

  return axios.post(url, body, options);
};

const PUT = async (
  endpoint: string,
  body: { [key: string]: any } | null,
  params?: { [key: string]: any } | null,
  headers?: { [key: string]: any } | null
): Promise<any> => {
  // Create the request URL
  const url = `${BASE_URL}${endpoint}`;
  const options: { [key: string]: any } = {
    headers: {
      ...defaultRequestHeaders(),
      ...headers
    },
    params
  };

  return axios.put(url, body, options);
};

const PATCH = async (
  endpoint: string,
  body: { [key: string]: any } | null,
  params?: { [key: string]: any } | null,
  headers?: { [key: string]: any } | null
): Promise<any> => {
  const url = `${BASE_URL}${endpoint}`;
  const options: { [key: string]: any } = {
    headers: {
      ...defaultRequestHeaders(),
      ...headers
    },
    params
  };
  return axios.patch(url, body, options);
};

const DELETE = async (
  endpoint: string,
  params?: { [key: string]: any } | null,
  headers?: { [key: string]: any } | null
): Promise<any> => {
  const url = `${BASE_URL}${endpoint}`;
  const options: { [key: string]: any } = {
    headers: {
      ...defaultRequestHeaders(),
      ...headers
    },
    params
  };
  return axios.delete(url, options);
};

// let failedQueue: any[] = [];

// const processQueue = (error: any, token = ''): void => {
//   failedQueue.forEach(prom => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

axios.interceptors.response.use(
  async (response: AxiosResponse<any>): Promise<AxiosResponse> =>
    new Promise((resolve, reject): void => {
      if (response?.data?.status.toLowerCase() === 'success') {
        resolve(response);
      } else {
        const error: AxiosError = {
          response,
          message: response.data.error,
          config: {} as AxiosRequestConfig,
          name: '',
          isAxiosError: true,
          toJSON: (): object => ({})
        };
        reject(error);
      }
    }),
  (error): any => {
    if (error.response.status === 401) {
      unAuthorizedStateHandler(); // TODO: continue tried api call with new token
    }
    return Promise.reject(error);
  }
);

export default { GET, GETBLOB, POST, PUT, PATCH, DELETE };
