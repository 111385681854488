export enum EarType {
  First = 'First',
  Second = 'Second'
}

enum ImageFormat {
  JPEG = 'jpg'
}

export interface EarImageData {
  userid: string | number;
  imgData: string;
  image_format: ImageFormat;
  ear_type: EarType;
  cardNumber: string | number;
}

export enum EarSide {
  Left = 'Left',
  Right = 'Right'
}
