import React, { useContext, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import 'styles/Configurator.scss';
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount';
import { setCustomFitStep } from '../../store/fittingActions/fittingActions';
import FittingContext from '../../store/FittingContext/FittingContext';
import CustomFitHeading from '../CustomFitHeading';
import HowItWorksModal from '../HowItWorksModal/HowItWorksModal';
import PhotoInstructionsModal from '../PhotoInstructionsModal/PhotoInstructionsModal';
import TopBar from '../TopBar';
import styles from './CustomFitWelcome.module.scss';

const CustomFitWelcome: React.FC = () => {
  const {
    dispatch,
    store: { accessCodeCart }
  } = useContext(FittingContext);

  const [isInstructionModalOpen, setInstructionModalOpen] = useState(false);
  const [isHowItWorksModalOpen, setHowItWorksModalOpen] = useState(false);

  const handleBackClick = (): void => dispatch(setCustomFitStep(1));
  const handleNextClick = (): void => dispatch(setCustomFitStep(3));

  return (
    <>
      <ScrollToTopOnMount />
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight }}
      >
        <div style={{ minHeight: window.innerHeight - 80 }}>
          <TopBar onBackClick={handleBackClick} />
          <Col className={`${styles.mainBody}`}>
            <CustomFitHeading
              title={`Hi ${accessCodeCart?.[0]?.address_details?.Name ?? ''}`}
            />
            <Col className="pl-0 pr-0 sixDigitDescDiv">
              Take a look at the short video and photo instructions before
              starting
            </Col>
            <Col className="pl-0 pr-0 cfWelcomeInstructionsDiv">
              <Row className="ml-0 mr-0">
                <Col xs="12" className={`${styles.instructionCard} mb-4`}>
                  <Col
                    className={`${styles.buttonText}`}
                    onClick={() => setHowItWorksModalOpen(true)}
                  >
                    How it works
                  </Col>
                </Col>
              </Row>
              <Row className="ml-0 mr-0">
                <Col xs="12" className={`${styles.instructionCard} mb-4`}>
                  <Col
                    className={`${styles.buttonText}`}
                    onClick={() => setInstructionModalOpen(true)}
                  >
                    Photo instructions
                  </Col>
                </Col>
              </Row>
            </Col>
          </Col>
        </div>
        <Col className={`text-center ${styles.btnContainer}`}>
          <Button onClick={handleNextClick} className={styles.letsGoBtn}>
            GET STARTED
          </Button>
        </Col>
        <HowItWorksModal
          isOpen={isHowItWorksModalOpen}
          toggle={() => setHowItWorksModalOpen(state => !state)}
        />
        <PhotoInstructionsModal
          isOpen={isInstructionModalOpen}
          toggle={() => setInstructionModalOpen(state => !state)}
        />
      </Container>
    </>
  );
};

export default CustomFitWelcome;
