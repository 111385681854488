import { AxiosResponse } from 'axios';
import api from '../api';
import { AuthRequest } from './userProfileCommand';

const USER_PROFILE = '/get_token';
const ORDERS = `/customer_orders_user`;
const USER_IMAGE_DETAILS = '/customer_image_details_filenames';

const getToken = async (payload: AuthRequest): Promise<AxiosResponse> =>
  api.POST(USER_PROFILE, payload);

const getOrders = async (userId: string): Promise<AxiosResponse> =>
  api.GET(ORDERS, { userId });

const getCustomerImageDetails = async (
  filenames: string
): Promise<AxiosResponse> => api.GET(USER_IMAGE_DETAILS, { filenames });

export default { getToken, getOrders, getCustomerImageDetails };
