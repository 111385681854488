import close from 'assets/images/Icons/close-red.svg';
import tick from 'assets/images/Icons/tick-green.svg';
import React from 'react';
import { Col, Container, Modal, ModalBody } from 'reactstrap';
import Assets from '.';
import PhotoInstructionCard from '../PhotoInstructionCard/PhotoInstructionCard';
import TopBar from '../TopBar';
import styles from './PhotoInstructionsModal.module.scss';

interface PhotoInstructionsModalProps {
  toggle: () => void;
  isOpen: boolean;
}
const PhotoInstructionsModal: React.FC<PhotoInstructionsModalProps> = (
  props: PhotoInstructionsModalProps
) => {
  const { toggle, isOpen } = props;
  return (
    <Modal
      size="xl"
      toggle={toggle}
      className="m-0"
      isOpen={isOpen}
      contentClassName={`${styles.modalContent} min-vw-100`}
    >
      <Container
        fluid
        className="pl-0 pr-0 bg-f9f9"
        style={{ minHeight: window.innerHeight }}
      >
        <TopBar
          hasBack={false}
          hasLeftButton
          onLeftButtonClick={toggle}
          title="PHOTO INSTRUCTIONS"
        />
        <ModalBody>
          <PhotoInstructionCard
            title="Correct photos"
            subTitle="Each ear photo should look like this"
            statusImage={tick}
            leftImage={Assets.correctLeft3x}
            rightImage={Assets.correctRight3x}
            srcsetLeft={[
              Assets.correctLeftNorm,
              Assets.correctLeft2x,
              Assets.correctLeft3x
            ]}
            srcsetRight={[
              Assets.correctRightNorm,
              Assets.correctRight2x,
              Assets.correctRight3x
            ]}
          />
          <Col className="text-center mb-32px pt-2">
            <span className={styles.commonIssues}>COMMON ISSUES</span>
          </Col>
          <PhotoInstructionCard
            title="Keep your ears clear"
            subTitle="Make sure no hair is covering your ear and remove any jewellery"
            statusImage={close}
            leftImage={Assets.earsClearLeft3x}
            rightImage={Assets.earsClearRight3x}
            srcsetLeft={[
              Assets.earsClearLeftNorm,
              Assets.earsClearLeft2x,
              Assets.earsClearLeft3x
            ]}
            srcsetRight={[
              Assets.earsClearRightNorm,
              Assets.earsClearRight2x,
              Assets.earsClearRight3x
            ]}
          />
          <PhotoInstructionCard
            title="Find good lighting"
            subTitle="Don’t use the flash and try to take the photos in natural light if possible"
            statusImage={close}
            leftImage={Assets.goodLightLeft3x}
            rightImage={Assets.goodLightRight3x}
            srcsetLeft={[
              Assets.goodLightLeftNorm,
              Assets.goodLightLeft2x,
              Assets.goodLightLeft3x
            ]}
            srcsetRight={[
              Assets.goodLightRightNorm,
              Assets.goodLightRight2x,
              Assets.goodLightRight3x
            ]}
          />
          <PhotoInstructionCard
            title="Line up correctly"
            subTitle="Make sure the fitting card is squared up correctly just inside the camera frame"
            statusImage={close}
            leftImage={Assets.lineUpLeft3x}
            rightImage={Assets.lineUpRight3x}
            srcsetLeft={[
              Assets.lineUpLeftNorm,
              Assets.lineUpLeft2x,
              Assets.lineUpLeft3x
            ]}
            srcsetRight={[
              Assets.lineUpRightNorm,
              Assets.lineUpRight2x,
              Assets.lineUpRight3x
            ]}
          />
        </ModalBody>
      </Container>
    </Modal>
  );
};

export default PhotoInstructionsModal;
