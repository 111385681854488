export const SET_CUSTOM_FIT_STEP = 'SET_CUSTOM_FIT_STEP';
export const SET_ACCESS_CODE_CART = 'SET_ACCESS_CODE_CART';
export const SET_ACCESS_CODE_ERR = 'SET_ACCESS_CODE_ERR';
export const SET_CARD_NO = 'SET_CARD_NO';
export const GRID_POINT_DETECT = 'GRID_POINT_DETECT';
export const CHECK_FIRST = 'CHECK_FIRST';
export const CHECK_SECOND = 'CHECK_SECOND';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const RESET_STATE = 'RESET_STATE';
export const RESET_STATE_FIRST = 'RESET_STATE_FIRST';
export const RESET_STATE_SECOND = 'RESET_STATE_SECOND';
export const RESET_STATE_FIRST_TRY = 'RESET_STATE_FIRST_TRY';
export const RESET_STATE_SECOND_TRY = 'RESET_STATE_SECOND_TRY';
