import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Container, Input } from 'reactstrap';
import detectionService from 'services/detectionService/detectionService';
import 'styles/Configurator.scss';
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount';
import {
  resetState,
  setAccessCart,
  setCardNumber,
  setCustomFitStep
} from '../../store/fittingActions/fittingActions';
import FittingContext from '../../store/FittingContext/FittingContext';
import CustomFitHeading from '../CustomFitHeading';
import TopBar from '../TopBar';
import styles from './CustomFitSixDigit.module.scss';

const CustomFitSixDigit: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;
  const { dispatch } = useContext(FittingContext);
  const [sixDigit, setSixDigit] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(setAccessCart([])); /* reset */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSixDigitCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => setSixDigit(event.target.value);

  const sixDigitNextClick = (): void => {
    if (
      !Number.isNaN(parseInt(sixDigit, 10)) &&
      parseInt(sixDigit, 10) >= 100000 &&
      parseInt(sixDigit, 10) <= 999999
    ) {
      setError('');
      dispatch(resetState());
      getCardDetailsAccessCode(sixDigit);
    } else {
      setError('Please enter correct code');
    }
  };

  const getCardDetailsAccessCode = async (
    sixDigitCode: string
  ): Promise<void> => {
    try {
      const finalResp = await detectionService.getCardAccessCode({
        access_code: sixDigitCode
      });

      if (finalResp.data?.results.length > 0) {
        dispatch(setAccessCart(finalResp.data?.results)); /* reset */
        if (
          finalResp.data?.results[0]?.order_details[0].Left_Ear_Filename ===
            '' ||
          finalResp.data?.results[0]?.order_details[0].Right_Ear_Filename === ''
        ) {
          dispatch(setCustomFitStep(2)); /* no ear photos send to next step */
        } else {
          dispatch(
            setCustomFitStep(4)
          ); /* has all ear photos send to final (all done) */
        }

        dispatch(setCardNumber(finalResp.data?.results[0].access_code));
      } else {
        throw new Error('Please enter a correct code'); // TODO: un-comment
        // dispatch(setCustomFitStep(2)); /* wrong code */
        // history.replace(`/customfit/2`);
      }
    } catch (err) {
      setError(err?.message ?? 'An error occurred, Please try after sometime.');
    }
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight }}
      >
        <div
          style={{
            minHeight: window.innerHeight - 80,
            position: 'relative'
          }}
        >
          <TopBar onBackClick={() => history.push('/')} />
          <Col className={`sixDigitOuterDiv ${styles.mainBody}`}>
            <CustomFitHeading title="Enter your 6-digit code" />
            <Col className="pl-0 pr-0 sixDigitDescDiv">
              Your code is in the custom fit email we sent after you ordered
              your SnugsGo
            </Col>
            <Col className="pl-0 pr-0 sixDigitIpDiv">
              <Input
                className={`sixDigitIp ${error ? styles.error : ''}`}
                type="number"
                placeholder="123456"
                min="100000"
                max="999999"
                value={sixDigit}
                onChange={handleSixDigitCodeChange}
              />
            </Col>
          </Col>
          <Col className={`sixDigiterrDiv mt-4 mb-2 ${styles.otpError}`}>
            {error}
          </Col>
        </div>
        <Col className={`text-center ${styles.btnContainer}`}>
          <Button
            className={styles.letsGoBtn}
            onClick={(): void => sixDigitNextClick()}
          >
            Next
          </Button>
        </Col>
      </Container>
    </>
  );
};

export default CustomFitSixDigit;
