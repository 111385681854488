import React, { useContext, useEffect } from 'react';
import 'styles/CustomLoader.scss';
import { AppContext } from 'shared/store/appStore/AppContext/AppContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resetLoaderContent } from 'shared/store/appStore/appActions/appActions';

const CustomLoader: React.FC = () => {
  const {
    state: {
      showLoader,
      loaderMessage,
      loaderHeader,
      background,
      spinnerName,
      isCustom,
      isError,
      isSuccess
    },
    dispatch
  } = useContext(AppContext);
  // console.log('showLoader:', showLoader);

  useEffect(() => {
    if (!!isError || !!isSuccess) {
      setTimeout(() => {
        dispatch(resetLoaderContent());
      }, 3000);
    }
  }, [dispatch, isError, isSuccess]);

  const renderContent = (): JSX.Element => {
    const showDefaultSpinner = !!isError || !!isSuccess ? false : !isCustom;

    return (
      <>
        {showLoader ? (
          <div className="CustomLoader" style={{ display: 'block' }}>
            <div className="CustLoaderOuterDiv react-confirm-alert-overlay">
              <div
                className="CustLoaderDiv react-confirm-alert"
                style={{ background: background || '#f1f1f1' }}
              >
                {isCustom && spinnerName ? (
                  <div className="CustLoadImage text-center">
                    <img alt="" src={spinnerName} />
                  </div>
                ) : (
                  undefined
                )}
                <div className="CustLoadHead text-center">
                  {isError ? (
                    <FontAwesomeIcon
                      className="cursor-p"
                      icon="exclamation-triangle"
                      color="#FF0000"
                    />
                  ) : (
                    undefined
                  )}{' '}
                  {loaderHeader}
                </div>
                <div className="CustLoadContent text-center">
                  {loaderMessage}
                </div>
                {showDefaultSpinner ? (
                  <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                  </div>
                ) : (
                  undefined
                )}
              </div>
            </div>
          </div>
        ) : (
          undefined
        )}
      </>
    );
  };

  return renderContent();
};

export default CustomLoader;
