import React, { createContext, useReducer } from 'react';
import fittingReducer from '../fittingReducer/fittingReducer';
import FittingStore from '../fittingReducer/fittingReducerModels';
import {
  FittingContextValues,
  FittingProviderProps
} from './FittingContextModels';

const initialStore = new FittingStore();
const FittingContext = createContext<FittingContextValues>({
  dispatch: () => ({}),
  store: initialStore
});

const FittingConsumer = FittingContext.Consumer;
const FittingProvider: React.FC<FittingProviderProps> = (
  props: FittingProviderProps
) => {
  const { children } = props;
  const [store, dispatch] = useReducer(fittingReducer, initialStore);

  return (
    <FittingContext.Provider value={{ dispatch, store }}>
      {children}
    </FittingContext.Provider>
  );
};

export default FittingContext;
export { FittingProvider, FittingConsumer };
