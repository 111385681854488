import { useContext, useEffect, useRef } from 'react';
import { AppContext } from 'shared/store/appStore/AppContext/AppContext';
import userProfileService from 'services/userProfileService/userProfileService';
import { setLoaderContent } from 'shared/store/appStore/appActions/appActions';
import { v4 as uuid } from 'uuid';
import { AuthRequest } from 'services/userProfileService/userProfileCommand';
import { JWT_TOKEN } from 'shared/constants/app';

function useJWTToken(): void {
  const { dispatch: appStateDispatch, onSetJWTData } = useContext(AppContext);
  const componentIsMounted = useRef(true);

  useEffect(
    () => () => {
      componentIsMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (componentIsMounted.current) {
      const generateJWT = async (tokenValues: AuthRequest): Promise<void> => {
        try {
          const response = await userProfileService.getToken(tokenValues);
          const {
            data: { token }
          } = response;

          localStorage.setItem(JWT_TOKEN, token);
          onSetJWTData(token);
        } catch (error) {
          if (error?.response?.status === 401) {
            throw new Error('An error occurred, Please try after sometime.');
          } else {
            appStateDispatch(
              setLoaderContent({
                showLoader: true,
                loaderHeader: 'Error',
                isCustom: false,
                loaderMessage: error
                  ? error.message
                  : 'An error occurred, Please try after sometime.',
                isError: true
              })
            );
          }
        }
      };

      const tokenValues: AuthRequest = {
        user_device_id: uuid(),
        user_agent: window.navigator.userAgent.replace(/\D+/g, '')
      };
      generateJWT(tokenValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useJWTToken;
