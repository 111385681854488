import CameraIcon from 'assets/images/Icons/Camera.svg';
import TickIcon from 'assets/images/TickIcon.png';
import React from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

type CapturePhotoButtonProps = {
  imageData: string;
  detectionStatus: boolean;
  title: string;
  onCaptureClick: () => void;
};
const CapturePhotoButton: React.FC<CapturePhotoButtonProps> = (
  props: CapturePhotoButtonProps
) => {
  const { imageData, detectionStatus, onCaptureClick, title } = props;
  return (
    <>
      <div className="cfEPhInnerPhotoOutDiv text-center">
        {imageData.length > 0 ? (
          <div className="cfEPhInnerPhotoDiv">
            <img
              alt="cfEPhInnerPhotoImg"
              className="cfEPhInnerPhotoImg"
              src={`data:image/png;base64,${imageData}`}
            />
          </div>
        ) : (
          <div className="cfEPhInnerPhotoDiv" />
        )}
      </div>
      <div className="cfEPhInnerHead">{title}</div>
      <div className="cfEPhInnerCamDiv text-center">
        {detectionStatus ? (
          <Button className="btn snugs-bg-btn-dis color-dflt configBtn">
            <img alt="" src={TickIcon} className="CheckIconImg" />
          </Button>
        ) : (
          <>
            {isIOS && !isSafari ? (
              <Button className="btn snugs-bg-1 color-dflt configBtn" disabled>
                <img alt="" src={CameraIcon} className="CameraIconImg" />
              </Button>
            ) : (
              <Link to="/capture">
                <Button
                  className="btn snugs-bg-1 color-dflt configBtn"
                  onClick={onCaptureClick}
                >
                  <img alt="" src={CameraIcon} className="CameraIconImg" />
                </Button>
              </Link>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CapturePhotoButton;
