import React, { PureComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import 'styles/Footer.scss';

interface Props {
  isNotMargin: boolean;
  noLinks?: boolean;
}

class Footer extends PureComponent<Props> {
  render(): JSX.Element {
    const { isNotMargin, noLinks = false } = this.props;
    return (
      <>
        <Col
          className={
            isNotMargin ? 'ml-0 mr-0 footerCol' : 'ml-0 mr-0 footerCol mt-4'
          }
        >
          <Row className="ml-0 mr-0">
            <Col xs="6" className="pl-0 pr-0 text-left footerColText">
              © Snugs
            </Col>
            <Col xs="6" className="pl-0 pr-0 text-right footerColText">
              {noLinks ? (
                <></>
              ) : (
                <>
                  <Link to="/privacypolicy">
                    <span>Privacy &amp; Cookies</span>
                  </Link>{' '}
                  |
                  <a
                    href={`${process.env.REACT_APP_EXTERNAL_LINK_SNUGS}terms-and-conditions/`}
                  >
                    <span>Ts&amp;Cs</span>
                  </a>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Footer;
