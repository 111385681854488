import React from 'react';
import { Col } from 'reactstrap';
import styles from './PhotoInstructionCard.module.scss';

interface PhotoInstructionCardProps {
  statusImage: string;
  leftImage: string;
  rightImage: string;
  title: string;
  subTitle: string;
  srcsetRight: string[];
  srcsetLeft: string[];
}

const PhotoInstructionCard: React.FC<PhotoInstructionCardProps> = (
  props: PhotoInstructionCardProps
) => {
  const {
    statusImage,
    leftImage,
    rightImage,
    title,
    subTitle,
    srcsetRight,
    srcsetLeft
  } = props;

  const srcsetLeftString = srcsetLeft
    .map((src, index) => `${src} ${index + 1}x`)
    .join(',');

  const srcsetRightString = srcsetRight
    .map((src, index) => `${src} ${index + 1}x`)
    .join(',');

  return (
    <div className={`${styles.instructionCard} mb-4`}>
      <div className={`${styles.cardBody}`}>
        <div className={`${styles.imgContainer}`}>
          <div className={styles.left}>
            <div id="left" className={styles.relative}>
              <img
                src={leftImage}
                srcSet={srcsetLeftString}
                className={styles.photo}
                alt=""
              />
              <img
                alt=""
                src={statusImage}
                className={styles.ovalContainerLeft}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div id="right" className={styles.relative}>
              <img
                src={rightImage}
                srcSet={srcsetRightString}
                className={styles.photo}
                alt=""
              />
              <img
                alt=""
                src={statusImage}
                className={styles.ovalContainerRight}
              />
            </div>
          </div>
        </div>
        <Col className={styles.subheadText}>{title}</Col>
        <Col className={styles.descriptionText}>{subTitle}</Col>
      </div>
    </div>
  );
};

PhotoInstructionCard.defaultProps = {
  srcsetLeft: [],
  srcsetRight: []
};
export default PhotoInstructionCard;
