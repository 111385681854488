import { EarSide } from '../storeModels';

export default class FittingStore {
  customFitStep: number;

  accessCodeCart: any[];

  cardNumber: string | number;

  firstImgData: string;

  secondImgData: string;

  apiErrorMessage: string;

  currentOPEar: string;

  firstChecked: boolean;

  secondChecked: boolean;

  firstGridStatus: boolean;

  secondGridStatus: boolean;

  firstGridDetectResponse: any;

  secondGridDetectResponse: any;

  firstPointStatus: boolean;

  secondPointStatus: boolean;

  firstPointDetectResponse: any;

  firstMultiPointDetectResponse: any;

  firstMultiPointStatus: boolean;

  secondPointDetectResponse: any;

  secondMultiPointDetectResponse: any;

  secondMultiPointStatus: boolean;

  firstEarType: EarSide | string;

  secondEarType: EarSide | string;

  isLoader: boolean;

  loadMessage: string;

  leftMatchStatus: boolean;

  rightMatchStatus: boolean;

  firstImgProcessingStatus: boolean;

  secondImgProcessingStatus: boolean;

  firstImgProcessingResponse: any;

  secondImgProcessingResponse: any;

  constructor() {
    this.customFitStep = 1;
    this.accessCodeCart = [];
    this.cardNumber = 0;
    this.firstImgData = '';
    this.secondImgData = '';
    this.apiErrorMessage = '';
    this.currentOPEar = '';
    this.firstChecked = false;
    this.secondChecked = false;
    this.firstGridStatus = false;
    this.secondGridStatus = false;
    this.firstGridDetectResponse = {};
    this.secondGridDetectResponse = {};
    this.firstPointStatus = false;
    this.secondPointStatus = false;
    this.firstPointDetectResponse = {};
    this.secondPointDetectResponse = {};
    this.firstMultiPointDetectResponse = {};
    this.secondMultiPointDetectResponse = {};
    this.firstMultiPointStatus = false;
    this.secondMultiPointStatus = false;
    this.firstEarType = '';
    this.secondEarType = '';
    this.isLoader = false;
    this.loadMessage = '';
    this.leftMatchStatus = false;
    this.rightMatchStatus = false;
    this.firstImgProcessingStatus = false;
    this.secondImgProcessingStatus = false;
    this.firstImgProcessingResponse = '';
    this.secondImgProcessingResponse = '';
  }
}
