import leftArrow from 'assets/images/leftArrow.png';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount';
import Footer from '../../../shared/components/Footer';

type TermsProps = {
  isHeader: boolean;
  isFooter: boolean;
} & RouteComponentProps;

const Terms: React.FC<TermsProps> = (props: TermsProps) => {
  const { isFooter = true, isHeader = true, history } = props;

  return (
    <>
      <ScrollToTopOnMount />
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight - 80 }}
      >
        {isHeader ? (
          <Col className="faqArticleHead basketTopHead">
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(): void => {
                history.go(-1);
              }}
              className="backArrSpan backArrSpanBasket"
              onClick={(): void => {
                history.go(-1);
              }}
            >
              <img alt="" src={leftArrow} className="backArrImg" />
            </div>
            Terms Of Use
          </Col>
        ) : (
          undefined
        )}

        <Col className="p-lr-25px">
          <Col className="ppCntDiv mt-4">
            <p>
              These Terms &amp; Conditions govern your use of the Snugs
              Earphones service (hereafter referred to as Snugs) and Snugs
              CustomFit App. By visiting the Snugs CustomFit App you accept, and
              agree to, the content of these Terms &amp; Conditions. We reserve
              the right to change the Terms &amp; Conditions at any time and it
              is your responsibility to check regularly before ordering. This
              does not affect your statutory rights.
            </p>
          </Col>

          <Col className="ppHeadDiv">Terms of Service</Col>
          <Col className="ppCntDiv">
            <p>
              When you place an order via Snugs CustomFit App, we will treat
              that action as an offer to buy. Once the order is placed you will
              receive confirmation in the form of an email and at this time we
              enter into a contract with you. We store the contract&rsquo;s
              content and will send you the details of your order via e-mail.
              You can find the terms here at all times.
            </p>
          </Col>

          <Col className="ppHeadDiv">
            Product Specifications and Suitability
          </Col>
          <Col className="ppCntDiv">
            <p>
              Published product details, specifications and suitability
              information is the best available at the time. Snugs shall not be
              held responsible for the accuracy of any advice given regarding
              the suitability (or otherwise) of any part/s required for the
              repair of an item. If you have ordered an item that has an
              unsuitable product description we will remedy this by providing
              you a full refund upon the return of the item. Please see our
              Returns page for details.
              <br /> We encourage you to ensure all spare parts are fitted by a
              competent person in accordance with manufacturer specifications.
              Snugs cannot be held liable for any damage caused by the fitting
              of a spare part.
              <br /> Please ensure that you have read your warranty conditions
              before attempting to install a part or otherwise repair item.
              Snugs cannot be held responsible if the item is damaged or your
              warranty is voided. Some manufacturer guarantees may be on
              condition that you use a qualified Audio or Audiology technician.
            </p>
          </Col>

          <Col className="ppHeadDiv">Returns Policy</Col>
          <Col className="ppCntDiv">
            <p>
              Snugs offer a very helpful and hassle-free returns policy. Click
              here to see full details of our&nbsp;
              <a href="https://snugs.com/returns/">returns policy.</a>
            </p>
          </Col>

          <Col className="ppHeadDiv">Gift returns</Col>
          <Col className="ppCntDiv">
            <p>
              Refunds for items bought as gifts can only be given to the
              original purchaser.
            </p>
          </Col>

          <Col className="ppHeadDiv">
            Availability / Estimated Delivery Times
          </Col>
          <Col className="ppCntDiv">
            <p>
              We do our best to ensure availability of all advertised products
              and endeavor to dispatch all parts within the quoted time. If
              there is a delay we will inform you of this via email and the
              product will be dispatched as soon as it is available. If you have
              ordered multiple items they may arrive separately.
            </p>
            <p>
              Snugs has no liability to you for any delay in the delivery of
              products ordered. We will do our best to get all orders sent out
              as quickly as possible. You have the right to cancel delayed
              orders at any time, just contact info@snugsfit.com with your order
              number.
            </p>
            <p>
              Occasionally we find ourselves having to withdraw an item because
              it has become obsolete or has been discontinued. If you have
              placed an order for an item that has been made obsolete we will
              inform you as soon as possible and offer you an alternative if one
              is available.
            </p>
          </Col>

          <Col className="ppHeadDiv">Returns</Col>
          <Col className="ppCntDiv">
            <p>
              You have the right to cancel your order and return your item
              within the quoted time frame. Please notify us through your
              account by clicking the returns link of your intention to return
              your item(s) by following the steps provided. &nbsp;For full
              return guidelines&nbsp;
              <a href="https://snugs.com/returns/">click here.</a>
            </p>
          </Col>

          <Col className="ppHeadDiv">Payment</Col>
          <Col className="ppCntDiv">
            <ul>
              <li>
                Payment over the Internet can be made via our secure check out
                pages using Visa, Visa Electron, Mastercard, Switch/Maestro or
                Solo cards, PayPal and Worldpay.
              </li>
              <li>
                Card charges will appear on your statement as &ldquo;Snugs
                Earphones Ltd&rdquo;.
              </li>
              <li>
                All prices are quoted in pounds sterling (&pound;) and include
                Value Added Tax (VAT) at UK rates, available on every product
                page. It may be necessary to adjust prices in the event of an
                increase in VAT rates, or as a result of a manufacturer price
                increase. Customers are entitled to a full refund if this is not
                acceptable.
              </li>
              <li>
                Due to the nature of the clearing system your credit card
                payment will be taken at the point of processing. If a problem
                in supply should occur, your payment method will be refunded as
                swiftly as possible. &nbsp;The refund may take a couple of days
                to show as cleared funds on your account, depending on payment
                provider.
              </li>
            </ul>
          </Col>

          <Col className="ppHeadDiv">Governed Law</Col>
          <Col className="ppCntDiv">
            <p>
              Snugs Earphones Terms and Conditions shall be governed by and
              construed in accordance with English Law. Disputes arising in
              connection with these Terms shall be subject to the exclusive
              jurisdiction of the English courts
            </p>
          </Col>

          <Col className="ppHeadDiv">Delivery</Col>
          <Col className="ppCntDiv">
            <p>
              UK orders will be delivered using Royal Mail, but some products
              may be shipped using DPD couriers.
            </p>
          </Col>

          <Col className="ppHeadDiv">Cancellations</Col>
          <Col className="ppCntDiv">
            <p>
              Orders may be cancelled at any time before they are dispatched,
              simply visit your account and follow the steps for a full refund.
              If the item has already been dispatched then please see our
              returns policy.
              <br /> Under The Consumer Protection (Distance Selling)
              Regulations 2000 you have a right to cancel your order in writing
              (except for customised products) seven working days beginning with
              the day after the day on which the consumer receives the goods.
              Please see returns policy for guidance on method and costs of
              return.
            </p>
          </Col>

          <Col className="ppHeadDiv">Reviews and Comments</Col>
          <Col className="ppCntDiv">
            <p>
              We encourage you to leave reviews, comments and product questions
              on our social media feeds. We regularly monitor this content and
              we reserve the right to remove, reject or edit it at any time. If
              you submit information to us as a review, product question or
              customer comment you understand that what you have submitted is
              not copyright protected and you are permitting its appearance on
              the Snugs Earphones website or social media feeds.
            </p>
          </Col>

          <Col className="ppHeadDiv">Liability</Col>
          <Col className="ppCntDiv">
            <p>
              We shall not be liable for incidental or consequential damages or
              expenses. This includes, but is not limited to, damage to
              property, lost income, profits, economic or commercial losses and
              third parties claims. We will do our best to get all orders
              dispatched as quickly as possible. We have no liability to you for
              any delay in the delivery of products ordered. You have the right
              to cancel delayed orders at any time and get a refund prior to the
              item being dispatched. This warranty does not affect your
              statutory rights.
            </p>
            <p>
              Snugs Earphones The contents of these pages (including pictures,
              designs, logos, photographs, text written and other materials) are
              the copyright trademark or registered trademark of Snugs
              Earphones, its content and technology providers and their
              respective owners.
            </p>
            <p>ALL RIGHTS RESERVED.</p>
            <p>
              The copying, modification, distribution, reproduction, or
              incorporation into any other work of part or all of the material
              available on this website in any form is prohibited, save that you
              may: &ndash; Copy, print or download extracts of the material on
              this website for the sole purpose of using this website or placing
              an order with Snugs Earphones.
              <br /> Copy, print or download the material on this site for the
              purpose of sending to individual third parties for their personal
              information provided that you acknowledge us as the source of the
              material and that you inform the third party that these conditions
              apply to them and that they must comply with them.
            </p>
          </Col>

          <Col className="ppHeadDiv">Our Details</Col>
          <Col className="ppCntDiv">
            <p>
              Snugs Earphones is a registered trademark
              <br /> Registered Address: Snugs Earphones, Windwhistle Farm,
              Treborough, Watchet, Somerset, TA23 0QW
              <br /> Company Registration Number UK 8627564
              <br /> VAT Registration Number GB 909 3949 84
            </p>
          </Col>
        </Col>
      </Container>
      {isFooter ? <Footer isNotMargin={false} noLinks /> : undefined}
    </>
  );
};

export default withRouter(Terms);
