import React from 'react';
import { Col, Button, Container } from 'reactstrap';

interface Props {
  children: any;
}

interface States {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, States> {
  static getDerivedStateFromError(error: any): any {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render(): JSX.Element {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Container className="p-25px ">
          <h5 className="text-center mt-3">
            Something wend wrong, please click on Refresh
          </h5>
          <Col className="mt-5 text-center">
            <Button
              className="btn snugsTopBtn"
              onClick={(): void => {
                window.location.href = '/';
              }}
            >
              Refresh
            </Button>
          </Col>
        </Container>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
