import 'styles/Header.scss';

import snugslogoPurple from 'assets/images/Icons/Snugs-Logo-Purple.svg';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

interface Props {
  isHome: boolean;
}

const Header: React.FC<Props> = (props: Props) => (
  <Row className="ml-0 mr-0 HeaderOuter text-center">
    <Col xs="8" className="text-center m-auto">
      <Link to="/">
        <img
          className="logo-header"
          src={snugslogoPurple}
          alt="snugs-logo"
          title={`SnugsFit Customfit | version: ${process.env.REACT_APP_VERSION}`}
        />
      </Link>
    </Col>
  </Row>
);

export default Header;
