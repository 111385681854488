import React from 'react';
import leftArrow from 'assets/images/Icons/Arrow-Left-Black.svg';
import { Col } from 'reactstrap';
import styles from './TopBar.module.scss';

type TopBarProps = {
  title?: string;
  hasBack?: boolean;
  hasLeftButton?: boolean;
  leftButtonTitle?: string;
  onLeftButtonClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  onBackClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
};

const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {
  const {
    onBackClick,
    hasBack,
    title,
    hasLeftButton,
    leftButtonTitle,
    onLeftButtonClick
  } = props;
  return (
    <Col className="text-center basketTopHead bg-white">
      {hasBack && (
        <div
          className="backArrSpan backArrSpanBasket"
          role="button"
          tabIndex={0}
          onKeyDown={onBackClick}
          onClick={onBackClick}
        >
          <img alt="" src={leftArrow} className="backArrImg" />
        </div>
      )}
      {hasLeftButton && (
        <div
          className={`${styles.okBtn} cookiePolicyCntSpan`}
          role="button"
          tabIndex={0}
          onKeyDown={onLeftButtonClick}
          onClick={onLeftButtonClick}
        >
          {leftButtonTitle || 'OK'}
        </div>
      )}
      {title || 'CUSTOM FIT'}
    </Col>
  );
};

TopBar.defaultProps = {
  hasBack: true,
  hasLeftButton: false
};

export default TopBar;
