import React from 'react';
import { Button } from 'reactstrap';
import { camelCaseToReadableText } from 'shared/utils/utils';

interface CamErrorOverlayProps {
  onDismiss: () => void;
  show: boolean;
  error: null | Error;
}

const getCustomErrorMessage = (error: Error): string => {
  switch (error.name) {
    case 'NotAllowedError':
      return 'Camera permission denied';
    default:
      return error.message;
  }
};

const CamErrorOverlay: React.FC<CamErrorOverlayProps> = (
  props: CamErrorOverlayProps
) => {
  const { show, onDismiss, error } = props;
  const height = window.innerHeight;

  const camOverLayStyle = {
    display: 'block',
    bottom: `${height * 0.05 + 100}px`
  };
  if (show && error) {
    return (
      <div
        className="camera_overlay_InstructionDiv pb-3"
        style={camOverLayStyle}
      >
        <div className="text-center">{camelCaseToReadableText(error.name)}</div>
        <div className="camera_overlay_InstructionDiv_Content">
          {getCustomErrorMessage(error)}
        </div>
        <div className="camera_overlay_InstructionDiv_Btn text-center">
          <Button className="btn cam_overlay_inst_btn" onClick={onDismiss}>
            Okay
          </Button>
        </div>
      </div>
    );
  }
  return <></>;
};

export default CamErrorOverlay;
