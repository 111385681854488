import 'styles/Basket.scss';
import 'styles/ChangeFit.scss';

import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import CustomLoader from 'shared/components/CustomLoader';
import DesktopErrScreen from 'shared/components/DesktopErrScreen';
import GATrackPageview from 'shared/components/GATrackPageview';
import useJWTToken from 'shared/hooks/useJWTToken';

import ScrollToTop from 'shared/components/ScrollToTop';
import routes from '../routes';

const RootNavigator: React.FC = () => {
  useJWTToken(); // get jwt token
  const renderContent = (): JSX.Element => (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {isMobile || isTablet ? (
          <>
            <div className="App">
              <Route component={GATrackPageview} />
              <Switch>
                {routes.map(route => (
                  <Route key={route.key} {...route} />
                ))}
              </Switch>
            </div>
          </>
        ) : (
          <DesktopErrScreen />
        )}
      </BrowserRouter>
      <CustomLoader />
    </>
  );

  return renderContent();
};

export default RootNavigator;
