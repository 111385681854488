import { RouteProps } from 'react-router-dom';
import Instructions from 'modules/fitting/screens/Instructions/Instructions';
import TakePhoto from 'modules/fitting/screens/TakePhoto';
import CustomFit from 'modules/fitting/CustomFit';
import LandingPage from 'modules/lading/screens/LandingPage';
import PrivacyPolicy from 'modules/fitting/screens/PrivacyPolicy';
import Terms from 'modules/fitting/screens/Terms';

type CustomRouteProps = RouteProps & { key: string };

const routes: CustomRouteProps[] = [
  { key: 'customFit', exact: true, path: '/', component: LandingPage },
  {
    key: 'customFit',
    exact: true,
    path: '/customfit',
    component: CustomFit
  },
  {
    key: 'capture',
    path: '/capture',
    component: TakePhoto
  },
  {
    key: 'instructions',
    path: '/instructions',
    component: Instructions
  },
  {
    key: 'privacypolicy',
    path: '/privacypolicy',
    component: PrivacyPolicy
  },
  {
    key: 'termscomponent',
    path: '/terms',
    component: Terms
  }
];

export default routes;
