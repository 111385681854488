import React, { useContext } from 'react';
import { Button, Col, Container } from 'reactstrap';
import 'styles/Configurator.scss';
import ScrollToTopOnMount from 'shared/components/ScrollToTopOnMount';
import Footer from '../../../shared/components/Footer';
import FittingContext from '../store/FittingContext/FittingContext';
import CustomFitHeading from './CustomFitHeading';

const CustomFitComplete: React.FC = () => {
  const {
    store: { accessCodeCart }
  } = useContext(FittingContext);

  const handleClickToSnugs = (): void => {
    window.location.href = process.env.REACT_APP_EXTERNAL_LINK_SNUGS as string;
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Container
        fluid
        className="pl-0 pr-0"
        style={{ minHeight: window.innerHeight }}
      >
        <div style={{ minHeight: '83vh' }}>
          <Col className="text-center basketTopHead bg-white">ALL DONE</Col>
          <Col className="p-lr-25px mt-4 cfWelcomeOuterDiv">
            <CustomFitHeading
              title={`Thanks, ${accessCodeCart?.[0]?.address_details?.Name ??
                ''}`}
            />
            <Col className="pl-1 pr-1 sixDigitDescDiv">
              We’ve received your ear photos. We’ll now make your Snugs and ship
              them to you in 7-10 working days.
              <br />
              <br />
              Thanks again for choosing Snugs. We’re really excited to have you
              experience them.
            </Col>
          </Col>
          <Col className="p-lr-25px pt-4 mb-4 cfWelcomeOuterDiv">
            <Button className="btn sixDigitNSBtn" onClick={handleClickToSnugs}>
              CONTINUE
            </Button>
          </Col>
          <div className="fixed-bottom">
            <Footer isNotMargin />
          </div>
        </div>
      </Container>
    </>
  );
};

export default CustomFitComplete;
